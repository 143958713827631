import _ from "lodash";

const key = "irat-completed-tour-targets";

const getState = () => JSON.parse(_.defaultTo(localStorage.getItem(key), "[]"));

const setState = (state) => localStorage.setItem(key, JSON.stringify(state));

export const setStepAsCompleted = (step) =>
  setState(_.uniq([...getState(), step.target]));

export const setAllStepsAsCompleted = (steps) =>
  setState(_.uniq([...getState(), ..._.map(steps, _.property("target"))]));

export const getIncompleteSteps = (steps) => {
  const targets = getState();
  return steps.filter(({ target }) => targets.indexOf(target) < 0);
};
