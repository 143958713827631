import "./Layout.scss";
import PropTypes from "proptypes";

export default function Layout({
  header,
  alerts,
  primaryNav,
  controls,
  secondaryNav,
  content,
  footer,
  modals,
}) {
  return (
    <div className="d-flex flex-column irat-layout">
      <div className="flex-grow-0 mb-3">{header}</div>
      <div className="flex-grow-1 container-fluid d-flex flex-column">
        {alerts}
        {primaryNav}
        <div className="d-flex flex-column flex-md-row flex-grow-1">
          {!!controls && (
            <div className="irat-layout__controls flex-shrink-0 flex-grow-0 position-relative">
              <div className="sticky-top">
                <div className="pt-3">{controls}</div>
              </div>
            </div>
          )}
          {!!controls && <div className="d-none d-md-block vr m-3 mb-0" />}
          <div className="flex-grow-1">
            {!!secondaryNav && <div className="pt-3">{secondaryNav}</div>}
            {!!secondaryNav && <hr />}
            {content}
          </div>
        </div>
      </div>
      <div className="flex-grow-0 container-fluid mb-3">
        <hr />
        {footer}
      </div>
      {modals}
    </div>
  );
}

Layout.propTypes = {
  header: PropTypes.node.isRequired,
  alerts: PropTypes.node.isRequired,
  primaryNav: PropTypes.node,
  controls: PropTypes.node,
  secondaryNav: PropTypes.node,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  modals: PropTypes.node,
};
