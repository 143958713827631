import _ from "lodash";

// @TODO: List weather stations inside of regions.
const weatherStations = [
  {
    value: "AyrDPI",
    label: "Ayr DPI Research Station",
    location: [-19.62, 147.38],
  },
  {
    value: "Clare",
    label: "Clare",
    location: [-19.79, 147.23],
  },
  {
    value: "Mackay",
    label: "Pleystowe Sugar Mill",
    location: [-21.1417, 149.0378],
  },
  {
    value: "Proserpine",
    label: "Proserpine Airport",
    location: [-20.4925, 148.555],
  },
  {
    value: "PlaneCreek",
    label: "Plane Creek Sugar Mill",
    location: [-21.427, 149.216],
  },
  {
    value: "Eton",
    label: "Eton Sunwater",
    location: [-21.268, 148.970],
  },
];

export default _.zipObject(_.map(weatherStations, "value"), weatherStations);
