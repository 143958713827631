import { faBug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React from "react";
import Accordion from "react-bootstrap/Accordion";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
          <h1>Something went wrong.</h1>
          <FontAwesomeIcon
            icon={faBug}
            size="5x"
            className="text-primary my-5"
          />
          <p className="text-center">
            Try refreshing the page.
            <br />
            If the issue persists, please report it to the i-RAT team.
          </p>
          <div>
            <button
              onClick={() => window.location.reload()}
              className="btn btn-outline-primary m-2"
            >
              Refresh
            </button>
            <a
              href="mailto:brian.collins@jcu.edu.au?Subject=iRAT%20App%20bug"
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-primary m-2"
            >
              Report
            </a>
          </div>
          <div className="mt-5 row w-100 justify-content-center">
            <div className="col col-lg-6 col-md-9">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Error details</Accordion.Header>
                  <Accordion.Body className="text-danger">
                    <p>{_.get(this.state.error, "message")}</p>
                    <p>{_.get(this.state.error, "stack")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export function TestError() {
  throw new Error("A test error");
}
