import ConfirmerModal from "./ConfirmerModal";
import Download from "./Download";
import PaddockSettingsButton from "./PaddockSettingsButton";
import Responsive from "./Responsive";
import ScenarioSettings from "./ScenarioSettings";
import ScenarioSettingsButton from "./ScenarioSettingsButton";
import interactions from "./interactions";
import regions from "./regions";
import * as settingIds from "./settingIds";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import _ from "lodash";
import PropTypes from "proptypes";

export default function Controls({
  checkPermission,
  paddock,
  pdfParameters,
  onPaddockChange,
}) {
  const scenariosSettings = _.sortBy(_.values(paddock.scenarios), "order").map(
    (scenario) => {
      return (
        <ScenarioSettings
          key={scenario.id}
          name={`${scenario.name} scenario`}
          values={_.pick(paddock.scenarios, [scenario.id])}
          onChange={(values) =>
            onPaddockChange(
              produce(paddock, (draft) => {
                draft.scenarios[scenario.id] = values[scenario.id];
              })
            )
          }
        />
      );
    }
  );
  const resetPaddockSettings = () => {
    const region =
      regions[paddock.scenarios.current.settings[settingIds.REGION]];
    const updatedPaddock = {
      ...paddock,
      scenarios: _.mapValues(paddock.scenarios, (scenario) => ({
        ...scenario,
        settings: {
          ...scenario.settings,
          ...region.scenarioDefaults[scenario.id],
        },
      })),
    };
    onPaddockChange(updatedPaddock);
  };
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <PaddockSettingsButton
            checkPermission={checkPermission}
            paddock={paddock}
            onChange={(scenarios) =>
              onPaddockChange({
                ...paddock,
                scenarios,
              })
            }
          />
          <Responsive below="md">
            <div className="pt-3" />
            <div className="irat-tour-settings__scenarios">
              <ScenarioSettingsButton>
                {scenariosSettings}
              </ScenarioSettingsButton>
            </div>
          </Responsive>
        </div>
        <Responsive below="md">
          <div className="flex-shrink-1 ms-3">
            <ConfirmerModal
              title="Reset paddock"
              body="Are you sure you want to reset all settings?"
              onConfirm={() => {
                interactions.publish({
                  category: "Settings",
                  action: "reset",
                });
                resetPaddockSettings();
              }}
            >
              <FontAwesomeIcon
                className="text-primary irat-tour-settings__reset"
                icon={faUndo}
                title="Reset all settings"
              />
            </ConfirmerModal>
          </div>
        </Responsive>
      </div>
      <hr className="mb-0" />
      <Responsive above="md">
        <div className="mt-3 irat-tour-settings__scenarios">
          {scenariosSettings}
        </div>
        <div className="mt-3">
          <hr />
          <Download pdfParameters={pdfParameters} />
          <br />
          <ConfirmerModal
            title="Reset settings"
            body="Are you sure you want to reset all settings?"
            onConfirm={() => {
              interactions.publish({
                category: "Settings",
                action: "reset",
              });
              resetPaddockSettings();
            }}
          >
            <button className="btn btn-link text-decoration-none p-0">
              <FontAwesomeIcon
                className="me-2 text-primary irat-tour-settings__reset"
                icon={faUndo}
                title="Reset all settings"
              />
              Reset all settings
            </button>
          </ConfirmerModal>
        </div>
      </Responsive>
    </>
  );
}

Controls.propTypes = {
  checkPermission: PropTypes.func.isRequired,
  paddock: PropTypes.shape({}).isRequired,
  onPaddockChange: PropTypes.func.isRequired,
  pdfParameters: PropTypes.shape({}).isRequired,
};
