import { COLOR_GRAY_600, COLOR_SECONDARY } from "./colors";
import { FONT_FAMILY_SANS_SERIF, HEADINGS_FONT_FAMILY } from "./fonts";
import _ from "lodash";

export const yAxisLabelMinWidth = 44;

export const fontSizes = {
  small: "14px",
  medium: "16px",
};

const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const defaults = {
  title: {
    style: {
      fontSize: fontSizes.medium,
    },
  },
  legend: {
    fontSize: fontSizes.small,
  },
  dataLabels: {
    style: {
      fontSize: fontSizes.small,
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: fontSizes.small,
      },
    },
    title: {
      style: {
        fontSize: fontSizes.medium,
        fontWeight: 400,
        color: COLOR_SECONDARY,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: fontSizes.small,
      },
    },
    title: {
      style: {
        fontSize: fontSizes.medium,
        fontWeight: 400,
        color: COLOR_SECONDARY,
      },
    },
  },
  tooltip: {
    style: {
      fontSize: fontSizes.small,
    },
  },
  chart: {
    animations: {
      speed: 250,
      animateGradually: false,
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    fontFamily: FONT_FAMILY_SANS_SERIF,
    redrawOnWindowResize: !isMobileDevice,
    foreColor: COLOR_SECONDARY,
  },
  noData: {
    text: "Loading...",
    style: {
      color: COLOR_GRAY_600,
      fontSize: "32px",
      fontFamily: HEADINGS_FONT_FAMILY,
    },
  },
};

const chartConfig = (config) => {
  return _.defaultsDeep(config, defaults);
};

export default chartConfig;
