import queue from "async/queue";
import axios from "axios";

export const setAuthToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const getPaddocksState = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}paddocks`);
  return data;
};

let onSuccess = () => {};

const q = queue(({ patch }, cb) => {
  axios
    .patch(`${process.env.REACT_APP_API_URL}paddocks`, patch)
    .then(({ data }) => {
      onSuccess();
      cb(null, data);
    })
    .catch((error) => cb(error));
});

export const updatePersistentPaddockState = (patch, callbacks) => {
  onSuccess = callbacks.onSuccess;
  q.push({ patch });
  q.error(callbacks.onError);
  q.drain(callbacks.onSaved);
};

export const getUsers = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}users`);
  return data;
};

export const addUser = async (user) => {
  await axios.post(`${process.env.REACT_APP_API_URL}users`, user);
};

export const updateUser = async (username, fields) => {
  await axios.patch(`${process.env.REACT_APP_API_URL}users`, {
    username,
    fields,
  });
};
