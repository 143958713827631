import FixedContainer from "./FixedContainer";
import chartConfig, { yAxisLabelMinWidth } from "./chartConfig";
import generateMovingAverage from "./movingAverage";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartWeatherTimeSeries({ unit, format, series }) {
  const seriesData = [];
  const yaxisAnnotations = [];
  series.forEach((seriesItem) => {
    seriesData.push({
      name: seriesItem.name,
      type: "line",
      data: seriesItem.data,
    });
  });
  const isShowingMovingAverage = _.first(series).data.length > 4;
  series.forEach((seriesItem) => {
    const data = seriesItem.data;
    if (isShowingMovingAverage) {
      seriesData.push({
        name: `${seriesItem.name} moving average`,
        type: "line",
        data: generateMovingAverage(data),
      });
      yaxisAnnotations.push({
        y: _.mean(_.map(data, (item) => item.y)),
        borderColor: seriesItem.color,
        strokeDashArray: [8, 4],
      });
    }
  });
  series.forEach((seriesItem) => {
    const data = seriesItem.data;
    if (data.length) {
      yaxisAnnotations.push({
        y: _.mean(_.map(data, (item) => item.y)),
        borderColor: seriesItem.color,
        strokeDashArray: [8, 4],
      });
    }
  });
  return (
    <>
      <FixedContainer>
        <ApexChart
          options={chartConfig({
            dataLabels: {
              enabled: false,
            },
            colors: _.concat(..._.times(3, _.constant(_.map(series, "color")))),
            xaxis: {
              type: "datetime",
              title: {
                text: "Year",
              },
              labels: {
                format: "yyyy",
              },
            },
            tooltip: {
              x: {
                format: "yyyy",
              },
              y: {
                formatter: (value) => format(value).value,
              },
            },
            yaxis: {
              title: {
                text: unit,
              },
              decimalsInFloat: 0,
              labels: {
                minWidth: yAxisLabelMinWidth,
              },
            },
            grid: {
              show: false,
            },
            stroke: {
              width: 2,
              dashArray: [
                ..._.times(series.length, _.constant(0)),
                ..._.times(series.length, _.constant(3)),
              ],
            },
            annotations: {
              yaxis: yaxisAnnotations,
            },
            legend: {
              show: series.length > 1,
              customLegendItems: _.map(series, "name"),
            },
          })}
          series={seriesData}
          height="100%"
        />
      </FixedContainer>
      <div className="text-center small mt-2">
        <ul className="list-inline mb-0">
          <li className="list-inline-item">
            <strong>Dashed line</strong> Long-term average
          </li>
          {isShowingMovingAverage && (
            <li className="list-inline-item">
              <strong>Dotted line</strong> 5-year moving average
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

ChartWeatherTimeSeries.propTypes = {
  unit: PropTypes.string.isRequired,
  format: PropTypes.func.isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
          y: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
};
