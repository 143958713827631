import AdminResources from "./AdminResources";
import AdminUsers from "./AdminUsers";
import Modal from "./Modal";
import PropTypes from "proptypes";
import React from "react";

export default function AdminModal({ show, onClose, user }) {
  return (
    <Modal fullscreen scrollable show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdminUsers user={user} />
        <hr className="my-5" />
        <AdminResources />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-dark" onClick={onClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

AdminModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};
