import "./ChartBar.scss";
import FixedContainer from "./FixedContainer";
import chartConfig from "./chartConfig";
import {
  COLOR_BETTER,
  COLOR_WORSE,
  COLOR_GRAY_600,
  COLOR_SAME,
  COLOR_SECONDARY,
} from "./colors";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ApexChart from "react-apexcharts";

const ChartBar = ({
  data,
  isLoading,
  name,
  xAxisTitle,
  yAxisTitle,
  isPositiveBetter,
  isHorizontal,
  showAnnotations,
  yMax,
}) => {
  const options = {
    xAxisTitle: isHorizontal ? xAxisTitle : yAxisTitle,
    yAxisTitle: isHorizontal ? yAxisTitle : xAxisTitle,
    dataLabelOffsetX: isHorizontal ? 10 : 0,
    dataLabelOffsetY: isHorizontal ? 0 : -20,
    dataLabelTextAnchor: isHorizontal ? "right" : "middle",
    data: isHorizontal ? data : _.reverse([...data]),
    marginTop: isHorizontal ? -10 : 0,
    hasLabelsBetween: _.has(_.first(data), "start"),
  };
  const [isAnimating, setIsAnimating] = useState(true);
  const colors = {
    increase: COLOR_SAME,
    decrease: COLOR_SAME,
  };
  if (_.isBoolean(isPositiveBetter)) {
    colors.increase = isPositiveBetter ? COLOR_BETTER : COLOR_WORSE;
    colors.decrease = isPositiveBetter ? COLOR_WORSE : COLOR_BETTER;
  }
  let annotations = {};
  if (showAnnotations && !isHorizontal) {
    annotations = {
      position: "back",
      xaxis: [
        {
          x: _.first(options.data).x,
          x2: options.data[_.toInteger(options.data.length / 2)].x,
          fillColor: colors.decrease,
          opacity: 0.1,
        },
        {
          x: options.data[_.toInteger(options.data.length / 2)].x,
          x2: _.last(options.data).x,
          fillColor: colors.increase,
          opacity: 0.1,
        },
      ],
    };
  }
  const chartOptions = chartConfig({
    chart: {
      events: {
        animationEnd: () => setIsAnimating(false),
      },
    },
    dataLabels: {
      formatter: (value) => (_.round(value) !== 0 ? _.round(value) : ""),
      textAnchor: options.dataLabelTextAnchor,
      offsetX: options.dataLabelOffsetX,
      offsetY: options.dataLabelOffsetY,
      style: { colors: [COLOR_GRAY_600] },
    },
    xaxis: {
      title: { text: options.xAxisTitle },
      labels: {
        hideOverlappingLabels: false,
        rotate: -45,
        rotateAlways: true,
        maxHeight: options.hasLabelsBetween ? 60 : 120,
        style: {
          colors: options.hasLabelsBetween ? "transparent" : COLOR_SECONDARY,
        },
      },
    },
    yaxis: { title: { text: options.yAxisTitle } },
    grid: { show: false },
    plotOptions: {
      bar: {
        horizontal: isHorizontal,
        dataLabels: {
          position: "top",
        },
      },
    },
    annotations,
  });
  if (!isHorizontal) {
    chartOptions.yaxis = {
      ...chartOptions.yaxis,
      max: (value) => value * (6 / 5),
      tickAmount: 6,
      forceNiceScale: true,
      labels: {
        ...chartOptions.yaxis.labels,
        formatter: (value) => (value > yMax ? "" : _.round(value)),
      },
    };
  }
  let labelData = [];
  if (!isLoading && options.hasLabelsBetween) {
    labelData = _.map(_.range(0, options.data.length * 4), (i) => {
      const before = options.data[_.floor(i / 4)];
      const after = options.data[_.ceil(i / 4)];
      let x = "";
      if (i % 4 === 0 && i > 0) {
        x = after.start || before.end;
      }
      return {
        fillColor: "transparent",
        x,
        y: _.max(_.map(options.data, "y")),
      };
    });
  }
  return (
    <div>
      {showAnnotations && !isHorizontal && (
        <div className="d-flex justify-content-around small mb-n3 mt-1 irat-chart-bar__annotations">
          <div style={{ color: colors.decrease }}>
            <FontAwesomeIcon icon={faArrowDown} /> decrease
          </div>
          <div style={{ color: colors.increase }}>
            <FontAwesomeIcon icon={faArrowUp} /> increase
          </div>
        </div>
      )}
      <FixedContainer
        className={isAnimating ? "irat-charts--is-animating" : ""}
      >
        <div
          className="h-100 position-relative"
          style={{ marginTop: options.marginTop }}
        >
          {options.hasLabelsBetween && (
            <ApexChart
              options={_.defaultsDeep(
                {},
                {
                  annotations: { xaxis: [] },
                  dataLabels: { enabled: false },
                  tooltip: { enabled: false },
                  xaxis: {
                    axisBorder: { color: "transparent" },
                    axisTicks: { color: "transparent" },
                    labels: { style: { colors: COLOR_SECONDARY } },
                    title: { style: { color: "transparent" } },
                  },
                  yaxis: {
                    labels: { style: { colors: ["transparent"] } },
                    title: { style: { color: "transparent" } },
                  },
                },
                chartOptions
              )}
              className="w-100 position-absolute top-0"
              series={[
                {
                  name,
                  data: isLoading ? [] : labelData,
                },
              ]}
              type="bar"
              height="100%"
            />
          )}
          <ApexChart
            options={chartOptions}
            series={[
              {
                name,
                data: isLoading ? [] : options.data,
              },
            ]}
            type="bar"
            height="100%"
          />
        </div>
      </FixedContainer>
    </div>
  );
};

ChartBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isPositiveBetter: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isHorizontal: PropTypes.bool.isRequired,
  showAnnotations: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  xAxisTitle: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  yMax: PropTypes.number.isRequired,
};

export default ChartBar;
