import {
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
} from "react";

export const AlertContext = createContext();

export default function Alerts() {
  const { alerts } = useAlerts();
  if (alerts.length === 0) {
    return null;
  }
  return alerts.map((alert) => (
    <div
      key={alert.id}
      className={`alert alert-${alert.type} mb-3`}
      role="alert"
    >
      {alert.message}
    </div>
  ));
}

export const withAlerts = (Component) => {
  return (props) => {
    const [alerts, setAlerts] = useState([]);
    const addAlert = useCallback(
      (alert) =>
        setAlerts((items) => {
          if (!items.find(({ id }) => id === alert.id)) {
            return [...items, alert];
          }
          return items;
        }),
      []
    );
    const removeAlert = useCallback(
      (alert) =>
        setAlerts((items) => {
          if (items.length === 0) {
            return items;
          }
          return items.filter(({ id }) => id !== alert.id);
        }),
      []
    );
    const value = useMemo(
      () => ({ alerts, addAlert, removeAlert }),
      [alerts, addAlert, removeAlert]
    );
    return (
      <AlertContext.Provider value={value}>
        <Component {...props} />
      </AlertContext.Provider>
    );
  };
};

export const useAlerts = () => useContext(AlertContext);
