import chartConfig, { yAxisLabelMinWidth } from "./chartConfig";
import { COLOR_PRIMARY } from "./colors";
import generateMovingAverage from "./movingAverage";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartRain({ data, mean, isLoading }) {
  let movingAverageData = [];
  const isShowingMovingAverage = data.length > 4;
  const series = [];
  if (!isLoading) {
    series.push({
      name: "Rain",
      type: "line",
      data,
    });
  }
  if (isShowingMovingAverage) {
    movingAverageData = generateMovingAverage(data);
    series.push({
      name: "Moving average",
      type: "line",
      data: movingAverageData,
    });
  }
  return (
    <>
      <ApexChart
        options={chartConfig({
          colors: [COLOR_PRIMARY],
          xaxis: {
            type: "datetime",
            title: {
              text: "Harvest year",
            },
            labels: {
              format: "yyyy",
            },
          },
          tooltip: {
            shared: true,
            x: {
              format: "yyyy",
            },
            y: {
              formatter: (value) => `${_.round(value, 2)} ML/ha`,
            },
          },
          yaxis: {
            title: {
              text: "ML/ha",
            },
            decimalsInFloat: 2,
            labels: {
              minWidth: yAxisLabelMinWidth,
            },
          },
          grid: {
            show: false,
          },
          stroke: {
            width: 2,
            dashArray: [0, 3],
          },
          annotations: {
            yaxis: [
              {
                y: mean,
                borderColor: COLOR_PRIMARY,
                strokeDashArray: [8, 4],
              },
            ],
          },
          legend: {
            show: false,
          },
        })}
        series={series}
      />
      <div className="text-center small">
        <ul className="list-inline mb-0">
          <li className="list-inline-item">
            <strong>Dashed line</strong> Long-term average
          </li>
          {isShowingMovingAverage && (
            <li className="list-inline-item">
              <strong>Dotted line</strong> 5-year moving average
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

ChartRain.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  mean: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
