import ChartBar from "./ChartBar";
import { COLOR_BETTER, COLOR_WORSE, COLOR_SAME } from "./colors";
import { generateCountData } from "./utils";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

const ChartCount = ({
  data,
  isPositiveBetter,
  changeThreshold,
  isHorizontal,
  isLoading,
  showAnnotations,
}) => {
  const themeColors = {
    better: COLOR_BETTER,
    same: COLOR_SAME,
    worse: COLOR_WORSE,
  };
  const countData = generateCountData(data, isPositiveBetter, changeThreshold);
  return (
    <ChartBar
      name="Seasons"
      isPositiveBetter={isPositiveBetter}
      xAxisTitle="Number of seasons"
      yAxisTitle="Relative change"
      isLoading={isLoading}
      isHorizontal={isHorizontal}
      showAnnotations={showAnnotations}
      data={countData.map(({ theme, ...rest }) => ({
        ...rest,
        fillColor: themeColors[theme],
      }))}
      yMax={_.values(data).length}
    />
  );
};

ChartCount.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isPositiveBetter: PropTypes.bool,
  changeThreshold: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isHorizontal: PropTypes.bool,
  showAnnotations: PropTypes.bool,
};

ChartCount.defaultProps = {
  isHorizontal: false,
  showAnnotations: true,
};

export default ChartCount;
