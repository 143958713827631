import colors from "./colors.module.scss";

export const COLOR_BETTER = colors.better;
export const COLOR_WORSE = colors.worse;
export const COLOR_SAME = colors.same;
export const COLOR_GRAY_100 = colors.gray100;
export const COLOR_GRAY_200 = colors.gray200;
export const COLOR_GRAY_300 = colors.gray300;
export const COLOR_GRAY_600 = colors.gray600;
export const COLOR_PRIMARY = colors.primary;
export const COLOR_A = colors.a;
export const COLOR_B = colors.b;
export const COLOR_SECONDARY = colors.secondary;
export const COLOR_LIGHT = colors.light;
export const COLOR_HIGH = COLOR_WORSE;
export const COLOR_RAIN = colors.rain;
export const COLOR_EVAP = colors.evap;
export const COLOR_TMIN = colors.tempmin;
export const COLOR_TMAX = colors.tempmax;
export const COLOR_RAD = colors.radiation;
