import { COLOR_BETTER, COLOR_WORSE, COLOR_SAME } from "./colors";
import { paddockSettings } from "./settings";
import _ from "lodash";

export const generateChangeData = (data, isPositiveBetter, threshold) => {
  const hasData = _.values(data).length > 0;
  const positiveColor = isPositiveBetter ? COLOR_BETTER : COLOR_WORSE;
  const negativeColor = isPositiveBetter ? COLOR_WORSE : COLOR_BETTER;
  const relativeChanges = _.values(data).map(
    (values) => (values.new - values.current) / values.current
  );
  const count = relativeChanges.length;
  const increased = hasData
    ? (100 * relativeChanges.filter((change) => change > threshold).length) /
      count
    : 0;
  const decreased = hasData
    ? (100 * relativeChanges.filter((change) => change < -threshold).length) /
      count
    : 0;
  return [
    {
      label: "Increased",
      color: positiveColor,
      percent: increased,
    },
    {
      label: "Decreased",
      color: negativeColor,
      percent: decreased,
    },
    {
      label: "Small/no change",
      color: COLOR_SAME,
      percent: 100 - increased - decreased,
    },
  ];
};

export const calculateRelativeChange = (a, b) => {
  if (a === 0 && b === 0) {
    return 0;
  }
  return (a - b) / Math.abs(b);
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const generateUniquePaddockName = (paddocks, paddock) => {
  const index = paddocks.indexOf(paddock);
  const priorPaddocks = _.slice(paddocks, 0, index);
  const priorPaddocksWithSameName = priorPaddocks.filter(
    ({ name }) => name === paddock.name
  );
  if (priorPaddocksWithSameName.length > 0) {
    return `${paddock.name} (${priorPaddocksWithSameName.length + 1})`;
  }
  return paddock.name;
};

export const generateCountData = (data, isPositiveBetter, changeThreshold) => {
  const themes = {
    positive: "same",
    negative: "same",
  };
  const changeThresholdAsPercent = 100 * changeThreshold;
  if (_.isBoolean(isPositiveBetter)) {
    themes.positive = isPositiveBetter ? "better" : "worse";
    themes.negative = isPositiveBetter ? "worse" : "better";
  }
  const relativeChanges = _.values(data).map(
    (values) => 100 * calculateRelativeChange(values.new, values.current)
  );
  const countChartData = [];
  countChartData.push({
    x: "⇧ over 20%",
    y: relativeChanges.filter((change) => change > 20).length,
    theme: themes.positive,
    start: "20%",
  });
  _.rangeRight(2, 5).forEach((i) =>
    countChartData.push({
      x: `⇧ ${5 * (i - 1)}-${5 * i}%`,
      y: relativeChanges.filter(
        (change) => (change > 5 * (i - 1)) & (change <= 5 * i)
      ).length,
      theme: themes.positive,
      start: `${5 * (i - 1)}%`,
      end: `${5 * i}%`,
    })
  );
  if (changeThresholdAsPercent < 5) {
    countChartData.push({
      x: `⇧ ${changeThresholdAsPercent}-5%`,
      y: relativeChanges.filter(
        (change) => (change > changeThresholdAsPercent) & (change <= 5)
      ).length,
      theme: themes.positive,

      start: `${changeThresholdAsPercent}%`,
      end: `5%`,
    });
  }
  countChartData.push({
    x: "Small/no change",
    y: relativeChanges.filter(
      (change) =>
        (change >= -changeThresholdAsPercent) &
        (change <= changeThresholdAsPercent)
    ).length,
    theme: "same",
    start: `-${changeThresholdAsPercent}%`,
    end: `${changeThresholdAsPercent}%`,
  });
  if (changeThresholdAsPercent < 5) {
    countChartData.push({
      x: `⇩ ${changeThresholdAsPercent}-5%`,
      y: relativeChanges.filter(
        (change) => (change >= -5) & (change < -changeThresholdAsPercent)
      ).length,
      theme: themes.negative,
      start: `-5%`,
      end: `-${changeThresholdAsPercent}%`,
    });
  }
  _.range(2, 5).forEach((i) =>
    countChartData.push({
      x: `⇩ ${5 * (i - 1)}-${5 * i}%`,
      y: relativeChanges.filter(
        (change) => (change >= -5 * i) & (change < -5 * (i - 1))
      ).length,
      theme: themes.negative,
      start: `-${5 * i}%`,
      end: `-${5 * (i - 1)}%`,
    })
  );
  countChartData.push({
    x: "⇩ over 20%",
    y: relativeChanges.filter((change) => change < -20).length,
    theme: themes.negative,
    end: `-20%`,
  });
  return countChartData;
};

export const getPaddocksSettings = (paddock) =>
  _.zipObject(
    _.map(paddockSettings, "id"),
    _.map(
      paddockSettings,
      ({ id }) => _.first(_.values(paddock.scenarios)).settings[id]
    )
  );
