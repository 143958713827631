import Modal from "./Modal";
import interactions from "./interactions";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "proptypes";
import React, { useState } from "react";

const DefaultHelpButton = (
  <FontAwesomeIcon icon={faQuestionCircle} className="text-dark ms-1" />
);

export default function Help({
  help: { title, contents },
  children,
  isEnabled,
}) {
  const [show, setShow] = useState(false);
  if (!isEnabled) {
    return children || null;
  }
  const theChildren = children || DefaultHelpButton;
  return (
    <>
      <span
        onClick={() => {
          interactions.publish({
            category: "Help",
            action: "opened",
            label: title,
          });
          setShow(true);
        }}
        className="text-decoration-none"
        role="button"
      >
        {theChildren}
      </span>
      <Modal size="lg" scrollable show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{contents}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-dark"
            onClick={() => setShow(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

Help.propTypes = {
  children: PropTypes.node,
  help: PropTypes.shape({
    contents: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isEnabled: PropTypes.bool,
};

Help.defaultProps = {
  children: false,
  isEnabled: true,
};
