import { useAuth } from "./AuthGate";
import Modal from "./Modal";
import PaddockSettings from "./PaddockSettings";
import ScenarioSettings from "./ScenarioSettings";
import createPaddock from "./createPaddock";
import * as settingIds from "./settingIds";
import { paddockSettings } from "./settings";
import classNames from "classnames";
import produce from "immer";
import _ from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { useEffect } from "react";

export default function AddPaddock({ children, onAdd, order }) {
  const { checkPermission } = useAuth();
  const [paddock, setPaddock] = useState(createPaddock("Paddock", order));
  const [show, setShow] = useState(false);
  useEffect(() => {
    setPaddock(createPaddock("Paddock", order));
  }, [order, show]);
  const hasPaddockName = !_.isEmpty(_.trim(paddock.name));
  return (
    <>
      {children(() => setShow(true))}
      <Modal
        size="xl"
        centered
        scrollable
        fullscreen="xl-down"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add paddock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Paddock name</h3>
          <input
            type="text"
            className={classNames("form-control w-auto", {
              "is-invalid": !hasPaddockName,
            })}
            placeholder="Paddock name"
            value={paddock.name}
            onChange={(e) =>
              setPaddock(
                produce(paddock, (draft) => {
                  draft.name = e.target.value;
                })
              )
            }
            onFocus={(e) => e.target.select()}
          />
          {!hasPaddockName && (
            <div class="invalid-feedback">Paddock name must not be empty</div>
          )}
          <hr />
          <PaddockSettings
            settings={_.filter(paddockSettings, {
              id: settingIds.WEATHER_STATION,
            })}
            checkPermission={checkPermission}
            values={paddock.scenarios}
            onChange={(scenarios) =>
              setPaddock(
                produce(paddock, (draft) => {
                  draft.scenarios = scenarios;
                })
              )
            }
          />
          <hr />
          <h3>Irrigation settings</h3>
          <div className="row">
            {_.sortBy(_.values(paddock.scenarios), "order").map((scenario) => (
              <div key={scenario.id} className="col-12 col-md-6">
                <ScenarioSettings
                  name={`${scenario.name} scenario`}
                  values={_.pick(paddock.scenarios, [scenario.id])}
                  onChange={(newValues) =>
                    setPaddock(
                      produce(paddock, (draft) => {
                        draft.scenarios[scenario.id] = newValues[scenario.id];
                      })
                    )
                  }
                />
              </div>
            ))}
          </div>
          <hr />
          <h3>Paddock settings</h3>
          <PaddockSettings
            settings={_.filter(
              paddockSettings,
              ({ id }) => id !== settingIds.WEATHER_STATION
            )}
            checkPermission={checkPermission}
            values={paddock.scenarios}
            onChange={(scenarios) =>
              setPaddock(
                produce(paddock, (draft) => {
                  draft.scenarios = scenarios;
                })
              )
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              onAdd(paddock);
              setShow(false);
            }}
            disabled={!hasPaddockName}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

AddPaddock.propTypes = {
  children: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  order: PropTypes.number,
};
