import AdminModal from "./AdminModal";
import Alerts, { withAlerts } from "./Alerts";
import AppWithPaddocks from "./AppWithPaddocks";
import AppWithoutPaddocks from "./AppWithoutPaddocks";
import { useAuth } from "./AuthGate";
import DisclaimerGate from "./DisclaimerGate";
import Footer from "./Footer";
import Header from "./Header";
import { MANAGE_USERS } from "./auth";
import { useAfterSignout, usePaddocksState } from "./hooks";
import interactions from "./interactions";
import withPaddocks from "./withPaddocks";
import { useLocalStorage } from "@rehooks/local-storage";
import _ from "lodash";
import { useState, useCallback } from "react";

function App({ paddocks }) {
  const { signOut, user, checkPermission } = useAuth();
  const paddockState = usePaddocksState(paddocks);
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [showDisclaimerGate, setShowDisclaimerGate] = useLocalStorage(
    "disclaimer-gate",
    true
  );
  // Prevent the disclaimer from briefly appearing when signing out.
  const setIsSigningOut = useAfterSignout(
    useCallback(() => setShowDisclaimerGate(true), [setShowDisclaimerGate])
  );
  const layoutProps = {
    footer: <Footer />,
    alerts: <Alerts />,
    modals: (
      <>
        <DisclaimerGate
          show={showDisclaimerGate}
          setShow={setShowDisclaimerGate}
        />
        {checkPermission(MANAGE_USERS) && (
          <AdminModal
            show={isAdminModalOpen}
            onClose={() => {
              interactions.publish({
                category: "Header",
                action: "admin modal closed",
              });
              setIsAdminModalOpen(false);
            }}
            user={user}
          />
        )}
      </>
    ),
    header: (
      <Header
        signOut={() => {
          interactions.publish({
            category: "Header",
            action: "signed out",
          });
          setIsSigningOut(true);
          signOut();
        }}
        isSaving={paddockState.isSaving}
        username={user.username}
        onAdminClick={
          checkPermission(MANAGE_USERS)
            ? () => {
                interactions.publish({
                  category: "Header",
                  action: "admin modal opened",
                });
                setIsAdminModalOpen(true);
              }
            : false
        }
      />
    ),
  };
  if (_.values(paddockState.paddocks).length === 0) {
    return (
      <AppWithoutPaddocks
        paddockState={paddockState}
        layoutProps={layoutProps}
      />
    );
  }
  return (
    <AppWithPaddocks
      paddockState={paddockState}
      layoutProps={layoutProps}
      showDisclaimerGate={showDisclaimerGate}
    />
  );
}

export default withAlerts(withPaddocks(App));
