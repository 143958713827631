import ChartChangeLegend from "./ChartChangeLegend";
import FixedContainer from "./FixedContainer";
import chartConfig, { yAxisLabelMinWidth } from "./chartConfig";
import { COLOR_BETTER, COLOR_WORSE, COLOR_SAME } from "./colors";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartDiff({
  data,
  unit,
  xLabel,
  isPositiveBetter,
  decimalPlaces,
  format,
  isLoading,
}) {
  const colors = {
    increase: COLOR_SAME,
    decrease: COLOR_SAME,
  };
  if (_.isBoolean(isPositiveBetter)) {
    colors.increase = isPositiveBetter ? COLOR_BETTER : COLOR_WORSE;
    colors.decrease = isPositiveBetter ? COLOR_WORSE : COLOR_BETTER;
  }
  const chartColors = _.values(data).map((values) => {
    const value = values.new - values.current;
    return value < 0 ? colors.decrease : colors.increase;
  });
  const differences = _.keys(data).map((year) => ({
    y: data[year].new - data[year].current,
    x: year,
  }));
  let max = _.max(differences.map(({ y }) => Math.abs(y)));
  // Only round y-axis to next integer if it's greater than 1.
  if (max > 1) {
    max = _.round(max + 0.5);
  }
  return (
    <>
      <FixedContainer>
        <ApexChart
          options={chartConfig({
            dataLabels: {
              enabled: false,
            },
            annotations: {
              yaxis: [
                {
                  y: 0,
                },
              ],
            },
            colors: chartColors,
            fill: {
              colors: [
                ({ value }) => {
                  return value < 0 ? colors.decrease : colors.increase;
                },
              ],
            },
            plotOptions: {
              bar: {
                distributed: true,
              },
            },
            xaxis: {
              type: "datetime",
              title: {
                text: xLabel,
              },
              labels: {
                format: "yyyy",
              },
            },
            tooltip: {
              x: {
                format: "yyyy",
              },
              y: {
                formatter: (seriesName, data) =>
                  format(data.series[data.seriesIndex][data.dataPointIndex])
                    .value,
                title: {
                  formatter: (seriesName, data) => {
                    const { sign } = format(
                      data.series[data.seriesIndex][data.dataPointIndex]
                    );
                    if (sign === 1) {
                      return "Increased by";
                    }
                    if (sign === -1) {
                      return "Decreased by";
                    }
                    return "Changed by";
                  },
                },
              },
              marker: {
                show: false,
              },
              shared: false,
              intersect: true,
            },
            yaxis: {
              min: -max,
              max,
              title: {
                text: unit,
              },
              decimalsInFloat: decimalPlaces,
              labels: {
                minWidth: yAxisLabelMinWidth,
              },
            },
            grid: {
              show: false,
            },
            stroke: {
              show: false,
            },
          })}
          series={[
            {
              type: "bar",
              data: isLoading ? [] : differences,
            },
          ]}
          height="100%"
        />
      </FixedContainer>
      <ChartChangeLegend
        type={_.isBoolean(isPositiveBetter) ? "default" : "neutral"}
      />
    </>
  );
}

ChartDiff.propTypes = {
  data: PropTypes.shape({}).isRequired,
  unit: PropTypes.string.isRequired,
  isPositiveBetter: PropTypes.bool,
  xLabel: PropTypes.string.isRequired,
  decimalPlaces: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
