import "./Tour.scss";
import * as tourState from "./tourState";
import { memo, useRef } from "react";
import Joyride, { ACTIONS, EVENTS } from "react-joyride";

const steps = [
  {
    target: ".irat-tour-paddock-nav__name",
    disableBeacon: true,
    content:
      "This is the default paddock. You can edit the name of a paddock by clicking on it.",
    placement: "top",
    title: "Default paddock",
  },
  {
    target: ".irat-tour-paddock-nav__add",
    disableBeacon: true,
    content: "You can add more paddocks by clicking on '+'.",
    placement: "top",
    title: "New paddock",
  },
  {
    target: ".irat-tour-paddock-settings-button",
    disableBeacon: true,
    content:
      "Describe the paddock here (region, weather station, soil, planting and tillage scenarios, water and energy price, etc.)",
    placement: "bottom",
  },
  {
    target: ".irat-tour-settings__scenarios",
    disableBeacon: true,
    content:
      "Describe 'current' and 'new' irrigation scenarios here. You need to determine irrigation frequency and amount.",
    placement: "bottom",
  },
  {
    target: ".irat-tour-paddock-nav",
    disableBeacon: true,
    content:
      "View the outputs here. 'Summary' tab summarises the impact of the change in irrigation practice. Other tabs provide visualisations.",
    placement: "bottom",
  },
  {
    target: ".irat-tour-header__sign-out",
    disableBeacon: true,
    content:
      "You'll automatically stay signed between visits. If you need to sign out you can do that here.",
    placement: "bottom",
    locale: {
      next: "Finish",
    },
  },
  {
    target: ".irat-tour-chart-type-selector",
    disableBeacon: true,
    content: "Switch between different data visualistaions here.",
    placement: "bottom",
  },
];

function Tour() {
  const incompleteSteps = tourState.getIncompleteSteps(steps);
  const helpers = useRef();
  const handleJoyrideCallback = (data) => {
    const { index, type, action } = data;
    if (action === ACTIONS.CLOSE) {
      tourState.setAllStepsAsCompleted(steps);
      helpers.current.skip();
    }
    if (type === EVENTS.TOOLTIP) {
      tourState.setStepAsCompleted(incompleteSteps[index]);
    }
  };
  return (
    <Joyride
      callback={handleJoyrideCallback}
      hideBackButton
      continuous
      disableScrolling
      getHelpers={(event) => {
        helpers.current = event;
      }}
      steps={incompleteSteps}
      styles={{
        options: { zIndex: 1020, primaryColor: "#f0ad4e" },
      }}
      locale={{
        last: "Finish",
        next: "Next",
      }}
    />
  );
}

export default memo(Tour);
