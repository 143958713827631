import "./Map.scss";
import { COLOR_PRIMARY } from "./colors";
import regions from "./regions";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { divIcon, latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import _ from "lodash";
import { useEffect } from "react";
import * as ReactDOMServer from "react-dom/server";
import { FeatureGroup } from "react-leaflet/FeatureGroup";
import { GeoJSON } from "react-leaflet/GeoJSON";
import { MapContainer } from "react-leaflet/MapContainer";
import { Marker } from "react-leaflet/Marker";
import { TileLayer } from "react-leaflet/TileLayer";
import { useMap } from "react-leaflet/hooks";

const iconSize = [45, 36];

const IconComponent = ({ isActive, label }) => (
  <div
    className="d-flex align-items-center"
    style={{ width: iconSize[0], height: iconSize[1] }}
  >
    <FontAwesomeIcon
      size="3x"
      icon={faLocationDot}
      className={classNames("flex-shrink-0", {
        "text-primary": isActive,
        "text-muted": !isActive,
      })}
      fixedWidth
    />
    <div
      className={classNames(
        "bg-white p-1 ms-n1 rounded border shadow text-nowrap",
        {
          "text-dark": isActive,
          "text-muted": !isActive,
        }
      )}
    >
      {label}
    </div>
  </div>
);

const generateIcon = ({ isActive, label }) =>
  divIcon({
    html: ReactDOMServer.renderToString(
      <IconComponent label={label} isActive={isActive} />
    ),
    className: "irat-map__icon",
    iconSize,
    iconAnchor: [iconSize[0] / 2, iconSize[1]],
  });

const MapContents = ({ value, onChange }) => {
  const markers = [];
  _.values(regions).forEach((region) =>
    region.weatherStations.forEach((item) =>
      markers.push(
        <Marker
          key={item.value}
          position={item.location}
          icon={generateIcon({
            label: item.label,
            isActive: value === item.value,
          })}
          eventHandlers={{
            click: () => {
              if (item.value !== value) {
                onChange({
                  weatherStation: item.value,
                  region: region.id,
                });
              }
            },
          }}
        />
      )
    )
  );
  const map = useMap();
  useEffect(() => {
    const bounds = latLngBounds(
      _.map(_.flatten(_.map(regions, "weatherStations")), "location")
    );
    map.fitBounds(bounds, { padding: [40, 40] });
  }, [map]);
  return <FeatureGroup>{markers}</FeatureGroup>;
};

export default function Map({ value, onChange }) {
  return (
    <MapContainer
      zoomSnap={0.25}
      scrollWheelZoom={false}
      className="h-100 w-100"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {_.values(regions).map((region) => (
        <GeoJSON
          key={region.id}
          data={region.geojson}
          style={() => ({
            stroke: COLOR_PRIMARY,
            weight: 2,
            fillColor: COLOR_PRIMARY,
            fillOpacity: 0.1,
          })}
        />
      ))}
      <MapContents value={value} onChange={onChange} />
    </MapContainer>
  );
}
