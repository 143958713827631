import { AuthContext, useAuth } from "./AuthGate";
import Charts, { getChartTabIds } from "./Charts";
import "./Pdf.scss";
import Summary from "./Summary";
import Weather from "./Weather";
import { getPaddockData } from "./api";
import { createPermissionChecker } from "./auth";
import { getPaddocksSettings } from "./utils";
import withPaddocks from "./withPaddocks";
import produce from "immer";
import _ from "lodash";
import { useState, useEffect } from "react";

function Pdf({ paddocks, paddockId, chartType }) {
  const { checkPermission } = useAuth();
  const sections = { Summary };
  getChartTabIds(checkPermission).forEach((id) => {
    sections[id] = Charts;
  });
  sections.Weather = Weather;
  const [data, setData] = useState({});
  const selectedPaddock = paddocks[paddockId];
  const isLoading = _.isUndefined(data[selectedPaddock.id]);
  useEffect(() => {
    const getData = async () => {
      setData((state) => _.omit(state, selectedPaddock.id));
      const paddockData = await getPaddockData(selectedPaddock);
      setData((state) =>
        produce(state, (draft) => {
          draft[selectedPaddock.id] = paddockData;
        })
      );
    };
    getData();
  }, [selectedPaddock]);
  const settings = getPaddocksSettings(selectedPaddock);
  return (
    <div className="irat-pdf container">
      {_.keys(sections).map((key) => {
        const Component = sections[key];
        return (
          <section className="irat-pdf__section mb-5" key={key}>
            <h2 className="border-bottom pb-2 mb-4">{key}</h2>
            <Component
              settings={settings}
              data={data[selectedPaddock.id]}
              tab={key}
              chartType={chartType}
              setChartType={() => {}}
              checkPermission={checkPermission}
              isLoading={isLoading}
              showHeader={false}
            />
          </section>
        );
      })}
    </div>
  );
}

const PdfWithPaddocks = withPaddocks(Pdf);

const searchParams = new URLSearchParams(window.location.search);

export default function PdfWithAuth() {
  const user = _.set(
    _.set(
      {},
      "signInUserSession.idToken.jwtToken",
      searchParams.get("accessToken")
    ),
    "signInUserSession.accessToken.payload.cognito:groups[0]",
    searchParams.get("role")
  );
  return (
    <AuthContext.Provider
      value={{ user, checkPermission: createPermissionChecker(user) }}
    >
      <PdfWithPaddocks
        paddockId={searchParams.get("paddockId")}
        chartType={searchParams.get("chartType")}
      />
    </AuthContext.Provider>
  );
}
