import AddPaddock from "./AddPaddock";
import Introduction from "./Introduction";
import Layout from "./Layout";
import PropTypes from "prop-types";

export default function AppWithoutPaddocks({ paddockState, layoutProps }) {
  return (
    <Layout
      {...layoutProps}
      content={
        <div className="h-100">
          <Introduction />
          <div className="text-center mt-2 mb-5">
            <AddPaddock
              onAdd={(paddock) =>
                paddockState.setPaddocks({
                  [paddock.id]: paddock,
                })
              }
            >
              {(show) => (
                <button onClick={show} className="btn btn-lg btn-primary">
                  Add paddock
                </button>
              )}
            </AddPaddock>
          </div>
          <div className="mx-n2">
            <img src="/intro.jpg" alt="water" width="100%" />
          </div>
        </div>
      }
    />
  );
}

AppWithoutPaddocks.propTypes = {
  paddockState: PropTypes.shape({}).isRequired,
  layoutProps: PropTypes.shape({}).isRequired,
};
