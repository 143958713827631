import "./ChartBox.scss";
import chartConfig from "./chartConfig";
import { COLOR_B } from "./colors";
import { FONT_FAMILY_SANS_SERIF } from "./fonts";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartBox({ data, unit, format, stats, isLoading }) {
  const boxData = _.keys(_.first(_.values(data))).map((key) => ({
    x: _.startCase(key),
    y: [
      stats["0.10 quantile"][key],
      stats["0.25 quantile"][key],
      stats.median[key],
      stats["0.75 quantile"][key],
      stats["0.90 quantile"][key],
    ],
  }));
  const values = _.flatten(_.map(boxData, (item) => item.y));
  const range = _.max(values) - _.min(values);
  const decimalPlaces = range < 1 ? 2 : 1;
  return (
    <div className="h-100 irat-chart-box" style={{ marginTop: -10 }}>
      <ApexChart
        options={chartConfig({
          grid: {
            show: false,
          },
          xaxis: {
            title: {
              text: unit,
            },
            labels: {
              formatter: (value) => _.round(value, decimalPlaces),
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "50%",
            },
            boxPlot: {
              colors: {
                upper: COLOR_B,
                lower: COLOR_B,
              },
            },
          },
          tooltip: {
            custom: ({ dataPointIndex, w, seriesIndex }) => {
              const { x, y } =
                w.config.series[seriesIndex].data[dataPointIndex];
              return `
                <div class="apexcharts-tooltip-title" style="font-family: ${FONT_FAMILY_SANS_SERIF};">${x}</div>
                <div class="apexcharts-tooltip-box apexcharts-tooltip-boxPlot">
                  <div>0.10 quantile: <span class="value">${
                    format(y[0]).value
                  }</span></div>
                  <div>0.25 quantile: <span class="value">${
                    format(y[1]).value
                  }</span></div>
                  <div>Median: <span class="value">${
                    format(y[2]).value
                  }</span></div>
                  <div>0.75 quantile: <span class="value">${
                    format(y[3]).value
                  }</span></div>
                  <div>0.90 quantile: <span class="value">${
                    format(y[4]).value
                  }</span></div>
                </div>
              `;
            },
          },
        })}
        series={[
          {
            type: "boxPlot",
            data: isLoading ? [] : boxData,
          },
        ]}
        type="boxPlot"
      />
    </div>
  );
}

ChartBox.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({}).isRequired,
  unit: PropTypes.string.isRequired,
  format: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    median: PropTypes.shape({
      new: PropTypes.number.isRequired,
      current: PropTypes.number.isRequired,
    }).isRequired,
    "0.10 quantile": PropTypes.shape({
      new: PropTypes.number.isRequired,
      current: PropTypes.number.isRequired,
    }).isRequired,
    "0.25 quantile": PropTypes.shape({
      new: PropTypes.number.isRequired,
      current: PropTypes.number.isRequired,
    }).isRequired,
    "0.75 quantile": PropTypes.shape({
      new: PropTypes.number.isRequired,
      current: PropTypes.number.isRequired,
    }).isRequired,
    "0.90 quantile": PropTypes.shape({
      new: PropTypes.number.isRequired,
      current: PropTypes.number.isRequired,
    }).isRequired,
  }),
};

ChartBox.defaultProps = {
  stats: {
    median: { current: 0, new: 0 },
    "0.10 quantile": { current: 0, new: 0 },
    "0.25 quantile": { current: 0, new: 0 },
    "0.75 quantile": { current: 0, new: 0 },
    "0.90 quantile": { current: 0, new: 0 },
  },
};
