import { getModalCountFromParams } from "./getModalCountFromParams";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";

const IratModal = ({ children, show, onHide, ...rest }) => {
  const count = useRef();
  const previousShowState = useRef(show);
  useEffect(() => {
    const listener = () => {
      if (show && getModalCountFromParams() + 1 === count.current) {
        previousShowState.current = false;
        onHide();
      }
    };
    window.addEventListener("popstate", listener, false);
    return () => {
      window.removeEventListener("popstate", listener);
    };
  }, [show, onHide]);
  useEffect(() => {
    if (previousShowState.current && !show) {
      window.history.back();
    }
    if (!previousShowState.current && show) {
      count.current = getModalCountFromParams() + 1;
      window.history.pushState("forward", null, `./?modal=${count.current}`);
    }
    previousShowState.current = show;
  }, [show]);
  useEffect(() => {
    return () => {
      if (previousShowState.current) {
        window.history.back();
      }
    };
  }, []);
  return (
    <Modal {...rest} show={show} onHide={onHide}>
      {children}
    </Modal>
  );
};

IratModal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default Object.assign(IratModal, Modal);
