import * as irrigationSystemIds from "./irrigationSystemIds.js";
import _ from "lodash";

export const lowFrequency = {
  value: "LowFreq",
  label: "14-day cycles at peak demand",
  irrigationSystems: [irrigationSystemIds.FURROW],
};

export const mediumFrequency = {
  value: "MediumFreq",
  label: "10-day cycles at peak demand",
  irrigationSystems: [irrigationSystemIds.FURROW],
};

export const highFrequency = {
  value: "HighFreq",
  label: "7-day cycles at peak demand",
  irrigationSystems: [irrigationSystemIds.FURROW],
};

export const swBased30drip = {
  value: "SWBased_600_30_15_8_30",
  label: "Irrigate when SWD > 30% PAW",
  irrigationSystems: [irrigationSystemIds.SUBSURFACE_DRIP],
};

export const swBased40 = {
  value: "SWBased_900_40_20_10_40",
  label: "Irrigate when SWD > 40% PAW",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_15,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

export const swBased60 = {
  value: "SWBased_900_60_30_15_60",
  label: "Irrigate when SWD > 60% PAW",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_15,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

export const swBased30 = {
  value: "SWD_0_30_30_30_30",
  label: "Irrigate when SWD > 30% PAW, early start",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_15,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

export const swBased30late = {
  value: "SWD_0_30_200_30_30",
  label: "Irrigate when SWD > 30% PAW, late start",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_15,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

export const swBased50 = {
  value: "SWD_0_50_50_50_50",
  label: "Irrigate when SWD > 50% PAW, early start",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_15,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

export const swBased50late = {
  value: "SWD_0_50_200_50_50",
  label: "Irrigate when SWD > 50% PAW, late start",
  irrigationSystems: [
    irrigationSystemIds.FURROW,
    irrigationSystemIds.LPOH_5_0,
    irrigationSystemIds.LPOH_5_10,
    irrigationSystemIds.LPOH_5_15,
    irrigationSystemIds.Traveller_15_0,
    irrigationSystemIds.Traveller_15_10,
    irrigationSystemIds.Traveller_15_25,
  ],
};

const irrigationFrequencyOptions = [
  lowFrequency,
  mediumFrequency,
  highFrequency,
  swBased30drip,
  swBased60,
  swBased30,
  swBased30late,
  swBased50,
  swBased50late,
];

export const furrowIrrigationFrequencies = _.map(
  _.filter(
    irrigationFrequencyOptions,
    ({ system }) => system === irrigationSystemIds.FURROW
  ),
  "value"
);
