import {
  faExclamationTriangle,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "proptypes";

export default function Loading({ error, isFullHeight }) {
  return (
    <div
      className={classNames(
        "d-flex align-items-center justify-content-center",
        {
          "vh-100": isFullHeight,
        }
      )}
    >
      <div className="h2 text-secondary">
        {error ? (
          <>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="me-3 text-danger"
            />
            Failed to load paddocks, try{" "}
            <span
              className="text-primary text-decoration-underline"
              role="button"
              onClick={() => window.location.reload()}
            >
              refreshing
            </span>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCircleNotch} spin className="me-3" />
            Loading...
          </>
        )}
      </div>
    </div>
  );
}

Loading.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]).isRequired,
  isFullHeight: PropTypes.bool,
};

Loading.defaultProps = {
  isFullHeight: true,
};
