import { FURROW } from "./irrigationSystemIds.js";
import * as settingIds from "./settingIds.js";
import { v4 as uuidv4 } from "uuid";

export const defaultSettings = {
  [settingIds.CANE_PRICE_CONSTANT]: 0.5,
  [settingIds.DRIP_ENERGY_USED]: 120,
  [settingIds.DRIP_OPERATION_COST]: 0,
  [settingIds.DRY_OFF_DAYS]: 35,
  [settingIds.ENERGY_COST]: 0.3,
  [settingIds.FALLOW]: "BareFallow",
  [settingIds.FERTILISER_AMOUNT]: 200,
  [settingIds.FERTILISER_DISTRIBUTION]: "Surf1",
  [settingIds.FUEL]: 98300,
  [settingIds.HARVEST_LOSS]: 0,
  [settingIds.HARVEST_YEARS]: {
    start: 1971,
    end: 2020,
  },
  [settingIds.IRRIGATION_ALLOCATION]: 999,
  [settingIds.IRRIGATION_SYSTEM]: FURROW,
  [settingIds.IS_BURNT]: true,
  [settingIds.PADDOCK_SIZE]: 1,
  [settingIds.PLANTING]: "15AprV1R4",
  [settingIds.RATOON]: "ALL",
  [settingIds.REGION]: "Burdekin",
  [settingIds.RELATIVE_CCS]: 15,
  [settingIds.SOIL]: "Mulg",
  [settingIds.SUGAR_PRICE]: 500,
  [settingIds.SURFACE_ENERGY_USED]: 70,
  [settingIds.SURFACE_OPERATION_COST]: 0,
  [settingIds.TILLAGE]: ["T1"],
  [settingIds.WATER_COST]: 20,
  [settingIds.WEATHER_STATION]: "AyrDPI",
};

const createPaddock = (name = "New paddock", order = 1) => ({
  id: uuidv4(),
  name,
  order,
  scenarios: {
    current: {
      id: "current",
      name: "Current",
      order: 1,
      settings: {
        ...defaultSettings,
        [settingIds.IRRIGATION_AMOUNT]: 140,
        [settingIds.IRRIGATION_FREQUENCY]: "LowFreq",
      },
    },
    new: {
      id: "new",
      name: "New",
      order: 2,
      settings: {
        ...defaultSettings,
        [settingIds.IRRIGATION_AMOUNT]: 110,
        [settingIds.IRRIGATION_FREQUENCY]: "LowFreq",
      },
    },
  },
});

export default createPaddock;
