import ChartChangeLegend from "./ChartChangeLegend";
import FixedContainer from "./FixedContainer";
import chartConfig from "./chartConfig";
import { COLOR_BETTER, COLOR_WORSE, COLOR_SAME } from "./colors";
import { calculateRelativeChange } from "./utils";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartScatter({
  data,
  isPositiveBetter,
  decimalPlaces,
  isLoading,
}) {
  const colors = {
    increase: COLOR_SAME,
    decrease: COLOR_SAME,
  };
  if (_.isBoolean(isPositiveBetter)) {
    colors.increase = isPositiveBetter ? COLOR_BETTER : COLOR_WORSE;
    colors.decrease = isPositiveBetter ? COLOR_WORSE : COLOR_BETTER;
  }
  const scatterData = _.values(data).map((values) => {
    const relativeChange =
      100 * calculateRelativeChange(values.new, values.current);
    return [values.current, relativeChange];
  });
  return (
    <>
      <FixedContainer>
        <ApexChart
          options={chartConfig({
            colors: [colors.increase, COLOR_SAME, colors.decrease],
            xaxis: {
              decimalsInFloat: decimalPlaces,
              title: {
                text: "Current value",
              },
              labels: {
                formatter: (value) => _.round(value, decimalPlaces),
              },
              tickAmount: 5,
            },
            legend: {
              show: false,
            },
            yaxis: {
              title: {
                text: "Relative change (%)",
              },
              labels: {
                formatter: (value) => _.round(value, decimalPlaces),
              },
            },
            grid: {
              show: false,
            },
            tooltip: {
              y: {
                formatter: (value) => `${_.round(value, decimalPlaces)}%`,
              },
            },
          })}
          series={
            isLoading
              ? []
              : [
                  {
                    name: "Increased",
                    data: scatterData.filter((item) => item[1] > 0),
                  },
                  {
                    name: "Same",
                    data: scatterData.filter((item) => item[1] === 0),
                  },
                  {
                    name: "Decreased",
                    data: scatterData.filter((item) => item[1] < 0),
                  },
                ]
          }
          type="scatter"
          height="100%"
        />
      </FixedContainer>
      <ChartChangeLegend
        type={_.isBoolean(isPositiveBetter) ? "default" : "neutral"}
      />
    </>
  );
}

ChartScatter.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isPositiveBetter: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  decimalPlaces: PropTypes.number.isRequired,
};
