import classNames from "classnames";
import PropTypes from "proptypes";

export default function ChartTypeSelector({ id, options, value, onChange }) {
  return (
    <div className="irat-tour-chart-type-selector">
      <div
        className={classNames("small", {
          "d-none d-md-block": options.length > 2,
        })}
      >
        {options.map((option) => (
          <div key={option} className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name={`${id}-radios`}
              id={`${id}-radios-${option}`}
              checked={value === option}
              onChange={() => onChange(option)}
            />
            <label
              className="form-check-label align-middle"
              htmlFor={`${id}-radios-${option}`}
            >
              {option}
            </label>
          </div>
        ))}
      </div>
      {options.length > 2 && (
        <div className="form-floating d-md-none my-3 text-dark">
          <select
            id={`${id}-select`}
            className="form-select"
            aria-label="Paddock nav"
            onChange={(e) => onChange(e.target.value)}
            value={value}
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <label htmlFor={`${id}-select`}>Chart type</label>
        </div>
      )}
    </div>
  );
}

ChartTypeSelector.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
};

ChartTypeSelector.defaultProps = {
  id: "chart-type-selector",
};
