import interactions from "./interactions";
import { faGithub, faAws, faTrello } from "@fortawesome/free-brands-svg-icons";
import { faBook, faExternalLink, faS } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const resources = [
  {
    title: "github",
    subtitle: "code repository",
    icon: faGithub,
    text: "github is used as the version control system for the i-RAT project.",
    url: "https://github.com/BrianCollinss/iRAT",
  },
  {
    title: "diagrams.net",
    subtitle: "documentation",
    icon: faBook,
    text: "diagrams.net is used for creating documentation for the i-RAT project.",
    url: "https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1&page-id=oVoVUc7kgVN521kiIY3D&title=iRAT.drawio#Uhttps%3A%2F%2Fdrive.google.com%2Fuc%3Fid%3D1Ub0r7m7_c7eYt_3apnfNJe5A7RW2P1O_%26export%3Ddownload",
  },
  {
    title: "AWS",
    subtitle: "web services",
    icon: faAws,
    text: "Amazon web services are used to host the i-RAT project infrastructure.",
    url: "https://212790358554.signin.aws.amazon.com/console",
  },
  {
    title: "Trello",
    subtitle: "collaboration",
    icon: faTrello,
    text: "Trello is used to manage the development of the i-RAT project.",
    url: "https://trello.com/b/7kcZ38bh/irat",
  },
  {
    title: "Storybook",
    subtitle: "UI components",
    icon: faS,
    text: "Storybook is used in the development of components for the UI within i-RAT.",
    url: "http://storybook.i-rat.net/",
  },
];

export default function AdminResources() {
  return (
    <>
      <h4 className="mb-4">Resources</h4>
      <ul className="row list-unstyled align-items-stretch justify-content-around">
        {resources.map((resource) => (
          <li
            className="col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-4"
            key={resource.title}
          >
            <div className="card mb-4 h-100">
              <div className="card-body d-flex flex-column h-100 justify-content-between">
                <div>
                  <h5 className="card-title">
                    <FontAwesomeIcon
                      icon={resource.icon}
                      className="text-primary me-2"
                    />
                    {resource.title}
                  </h5>
                  <h6 className="card-title">{resource.subtitle}</h6>
                </div>
                <p className="card-text">{resource.text}</p>
                <a
                  href={resource.url}
                  className="card-link"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    interactions.publish({
                      category: "Admin resources",
                      action: "opened",
                      label: resource.title,
                    })
                  }
                >
                  go to {resource.title}{" "}
                  <FontAwesomeIcon icon={faExternalLink} className="ms-2" />
                </a>
              </div>
            </div>
            {resource.name}
          </li>
        ))}
      </ul>
    </>
  );
}
