import _ from "lodash";

const currencyFormat = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "AUD",
  currencyDisplay: "narrowSymbol",
  signDisplay: "never",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const integerFormat = new Intl.NumberFormat(undefined, {
  signDisplay: "never",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const decimalFormat = new Intl.NumberFormat(undefined, {
  signDisplay: "never",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export const currency = (number) => ({
  sign: Math.sign(_.round(number)),
  value: currencyFormat.format(_.round(number)),
});

export const weightPerMegaLiterFromTonne = (number) => {
  const result = weightFromTonne(number);
  result.value += "/ML";
  return result;
};

export const times = (number) => ({
  sign: Math.sign(_.round(number)),
  value: `${integerFormat.format(number)} times`,
});

export const weightPerKilogramFromTonne = (number) => {
  const result = weightFromTonne(number);
  result.value += "/kg";
  return result;
};
export const weightOfCarbonDioxideFromKg = (number) => {
  const result = weightFromKg(number);
  result.value += " CO₂-e";
  return result;
};

export const weightOfCarbonDioxideFromTonne = (number) => {
  const result = weightFromTonne(number);
  result.value += " CO₂-e";
  return result;
};

export const megaLiters = (number) => {
  if (_.round(Math.abs(number)) < 100) {
    return {
      sign: Math.sign(_.round(number, 1)),
      value: `${decimalFormat.format(number)} ML`,
    };
  }
  return {
    sign: Math.sign(_.round(number)),
    value: `${integerFormat.format(number)} ML`,
  };
};

export const powerFromKwh = (number) => {
  if (_.round(Math.abs(number)) < 10000) {
    return {
      sign: Math.sign(_.round(number)),
      value: `${integerFormat.format(_.round(number))} kWh`,
    };
  }
  return {
    sign: Math.sign(_.round(number / 1000)),
    value: `${integerFormat.format(_.round(number / 1000))} MWh`,
  };
};

export const degreesCelsius = (number) => ({
  sign: Math.sign(_.round(number)),
  value: `${decimalFormat.format(number)} °C`,
});

export const kilowattHoursPerSquareMetre = (number) => ({
  sign: Math.sign(_.round(number)),
  value: `${integerFormat.format(_.round(number))} MJ/m²`,
});

export const millimetres = (number) => ({
  sign: Math.sign(_.round(number)),
  value: `${integerFormat.format(number)} mm`,
});

export const weightFromKg = (number) => {
  if (Math.abs(number) < 10) {
    return {
      sign: Math.sign(_.round(number, 1)),
      value: `${decimalFormat.format(number)} kg`,
    };
  }
  if (Math.abs(_.round(number)) < 1000) {
    return {
      sign: Math.sign(_.round(number)),
      value: `${integerFormat.format(number)} kg`,
    };
  }
  if (Math.abs(number / 1000) < 10) {
    return {
      sign: Math.sign(_.round(number / 1000, 1)),
      value: `${decimalFormat.format(number / 1000)} t`,
    };
  }
  return {
    sign: Math.sign(_.round(number / 1000)),
    value: `${integerFormat.format(number / 1000)} t`,
  };
};

export const weightFromTonne = (number) => weightFromKg(number * 1000);

export const kilogramNitrogenPerTonneCane = (number) => ({
  sign: Math.sign(_.round(number)),
  value: `${integerFormat.format(_.round(number))} kg N / t cane`,
});
