import ChartBar from "./ChartBar";
import { COLOR_BETTER, COLOR_WORSE, COLOR_SAME } from "./colors";
import { calculateRelativeChange } from "./utils";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

export const generateProbabilityChartData = (
  data,
  isPositiveBetter,
  changeThreshold
) => {
  const colors = {
    increase: COLOR_SAME,
    decrease: COLOR_SAME,
  };
  if (_.isBoolean(isPositiveBetter)) {
    colors.increase = isPositiveBetter ? COLOR_BETTER : COLOR_WORSE;
    colors.decrease = isPositiveBetter ? COLOR_WORSE : COLOR_BETTER;
  }
  const relativeChanges = _.values(data).map((values) =>
    calculateRelativeChange(values.new, values.current)
  );
  const count = relativeChanges.length;
  const probabilityChartData = [];
  _.rangeRight(1, 5).forEach((i) =>
    probabilityChartData.push({
      x: `⇧ over ${5 * i}%`,
      y:
        (100 * relativeChanges.filter((change) => change > 0.05 * i).length) /
        count,
      fillColor: colors.increase,
    })
  );
  if (changeThreshold < 0.05) {
    probabilityChartData.push({
      x: `⇧ over ${changeThreshold * 100}%`,
      y:
        (100 *
          relativeChanges.filter((change) => change > changeThreshold).length) /
        count,
      fillColor: colors.increase,
    });
  }
  probabilityChartData.push({
    x: "Small/no change",
    y:
      (100 *
        relativeChanges.filter(
          (change) => change >= -changeThreshold && change <= changeThreshold
        ).length) /
      count,
    fillColor: COLOR_SAME,
  });
  if (changeThreshold < 0.05) {
    probabilityChartData.push({
      x: `⇩ over ${changeThreshold * 100}%`,
      y:
        (100 *
          relativeChanges.filter((change) => change < -changeThreshold)
            .length) /
        count,
      fillColor: colors.decrease,
    });
  }
  _.range(1, 5).forEach((i) =>
    probabilityChartData.push({
      x: `⇩ over ${5 * i}%`,
      y:
        (100 * relativeChanges.filter((change) => change < -0.05 * i).length) /
        count,
      fillColor: colors.decrease,
    })
  );
  return probabilityChartData;
};

const ChartProbability = ({
  data,
  isPositiveBetter,
  changeThreshold,
  isHorizontal,
  isLoading,
  showAnnotations,
}) => {
  const chartData = generateProbabilityChartData(
    data,
    isPositiveBetter,
    changeThreshold
  );
  return (
    <ChartBar
      name="Probability"
      isPositiveBetter={isPositiveBetter}
      xAxisTitle="Probability (%)"
      yAxisTitle="Relative change"
      isLoading={isLoading}
      isHorizontal={isHorizontal}
      showAnnotations={showAnnotations}
      data={chartData}
      yMax={100}
    />
  );
};

ChartProbability.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isPositiveBetter: PropTypes.bool,
  changeThreshold: PropTypes.number.isRequired,
  isHorizontal: PropTypes.bool,
  showAnnotations: PropTypes.bool,
};

ChartProbability.defaultProps = {
  isHorizontal: false,
  showAnnotations: true,
};

export default ChartProbability;
