import "./SummaryCard.scss";
import icons, { getFaIcon } from "./icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowDown,
  faArrowUp,
  faCheck,
  faCircleNotch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "proptypes";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const themesAttributes = {
  better: {
    icon: faCheck,
    text: "new scenario is better",
  },
  worse: {
    icon: faTimes,
    text: "new scenario is worse",
  },
  same: {
    text: "small/no change",
  },
  neutral: {
    text: "neutral",
  },
  loading: {
    text: "loading...",
  },
  unknown: {
    text: "\u00A0",
  },
};

/**
 * Used on the summary page to the show potential change when switching from the current scenario to the new scenario.
 */
export default function SummaryCard({
  icon,
  name,
  theme,
  value,
  direction,
  isLoading,
  showPerHa,
  perHaValue,
  tooltip,
}) {
  if (!icon) {
    throw new Error(`No icon set in field ${name}`);
  }
  const finalTheme = isLoading ? "loading" : theme;
  const themeAttributes = themesAttributes[finalTheme];
  return (
    <div
      className={classNames(
        "rounded d-flex flex-column h-100",
        "irat-summary-card",
        `irat-summary-card--is-${finalTheme}`
      )}
    >
      <div className="d-flex align-items-center justify-content-between h-100 w-100">
        <div className="flex-grow-1 p-3 pb-2">
          <div className="m-0 h5">
            {name}
            {!!tooltip && (
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                overlay={
                  <Tooltip id={`${name.replaceAll(" ", "-")}-tooltip`}>
                    {tooltip.text}
                    {tooltip.url && (
                      <>
                        {" "}
                        <a
                          href={tooltip.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-white"
                        >
                          Read more
                        </a>
                        .
                      </>
                    )}
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  role="button"
                  icon={faQuestionCircle}
                  className="ms-2"
                />
              </OverlayTrigger>
            )}
          </div>
          <hr className="my-2" />
          <strong className="irat-summary-card__value">
            {isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            ) : (
              <div className="d-flex flex-wrap justify-content-between align-items-end">
                <div className="flex-shrink-0">
                  {!!direction && (
                    <FontAwesomeIcon
                      icon={direction === "up" ? faArrowUp : faArrowDown}
                    />
                  )}{" "}
                  {value}
                </div>
                {showPerHa && !!perHaValue && (
                  <div className="flex-shrink-0 small text-secondary fw-normal">
                    {`${perHaValue} / ha`}
                  </div>
                )}
              </div>
            )}
          </strong>
        </div>
        <FontAwesomeIcon icon={getFaIcon(icon)} size="2x" className="me-3" />
      </div>
      <div className="irat-summary-card__status align-self-end text-white px-1 w-100 d-flex align-items-center">
        {!!themeAttributes.icon && (
          <div className="d-inline-block ms-1">
            <FontAwesomeIcon fixedWidth icon={themeAttributes.icon} />
          </div>
        )}
        <div className="d-inline-block m-1 fw-bold">{themeAttributes.text}</div>
      </div>
    </div>
  );
}

SummaryCard.propTypes = {
  /** A string representation of a font awesome icon, must be first defined in icons.js */
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  /** The name of the summary */
  name: PropTypes.string.isRequired,
  /** The visual theme of the summary, green is better, red is worse */
  theme: PropTypes.oneOf([
    "same",
    "neutral",
    "better",
    "worse",
    "loading",
    "unknown",
  ]).isRequired,
  /** The value of the difference between the scenarios including any units */
  value: PropTypes.string.isRequired,
  /** Direction of change between the current and new scenarios */
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** If the summary is loading or not */
  isLoading: PropTypes.bool,
  /** Should the summary card show a per ha value */
  showPerHa: PropTypes.bool,
  /** The per ha value of the difference between the scenarios including any units */
  perHaValue: PropTypes.string,
  /** Optional text to show in a tooltip */
  tooltip: PropTypes.oneOfType([
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
};

SummaryCard.defaultProps = {
  direction: false,
  isLoading: false,
  showPerHa: false,
  perHaValue: "",
  tooltip: false,
};
