import { getUsers, addUser, updateUser } from "./user";
import { useState, useEffect } from "react";

const withAdminUsers =
  (WrappedComponent) =>
  ({ user }) => {
    const [users, setUsers] = useState([]);
    useEffect(() => {
      const fetchUsers = async () => {
        const response = await getUsers();
        setUsers(response);
      };
      fetchUsers();
    }, []);
    const [userToAdd, setUserToAdd] = useState(false);
    useEffect(() => {
      if (userToAdd) {
        const sendUser = async () => {
          await addUser(userToAdd);
          const response = await getUsers();
          setUsers(response);
          setUserToAdd(false);
        };
        sendUser();
      }
    }, [userToAdd]);
    return (
      <WrappedComponent
        users={users}
        add={(user) => setUserToAdd(user)}
        update={(username, fields) => {
          updateUser(username, fields);
          const updatedUsers = users.map((item) => {
            if (item.username === username) {
              return { ...item, ...fields };
            }
            return item;
          });
          setUsers(updatedUsers);
        }}
        currentUsername={user.username}
        isAdding={!!userToAdd}
      />
    );
  };

export default withAdminUsers;
