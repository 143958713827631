import ChartChangeLegend from "./ChartChangeLegend";
import chartConfig, { fontSizes } from "./chartConfig";
import { COLOR_SAME } from "./colors";
import { generateChangeData } from "./utils";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartTreemap({
  data,
  isPositiveBetter,
  changeThreshold,
  decimalPlaces,
  isLoading,
  labelThreshold,
}) {
  const changes = generateChangeData(data, isPositiveBetter, changeThreshold);
  return (
    <div className="overflow-hidden position-relative">
      <div style={{ marginLeft: 5, marginRight: -44, marginTop: -10 }}>
        <ApexChart
          options={chartConfig({
            dataLabels: {
              formatter: (value, { dataPointIndex, w }) => {
                const percent = w.config.series[0].data[dataPointIndex].y;
                if (percent < labelThreshold) {
                  return "";
                }
                return `${_.round(percent, 1)}%`;
              },
              style: {
                fontSize: fontSizes.medium,
              },
            },
            colors: _.isBoolean(isPositiveBetter)
              ? _.map(changes, "color")
              : _.times(changes.length, () => COLOR_SAME),
            plotOptions: {
              treemap: {
                distributed: true,
                enableShades: false,
              },
            },
            tooltip: {
              y: {
                formatter: (value) => `${_.round(value, decimalPlaces)}%`,
              },
            },
          })}
          series={
            isLoading
              ? []
              : [
                  {
                    data: changes.map(({ label, percent }) => ({
                      x: label,
                      y: percent,
                    })),
                  },
                ]
          }
          type="treemap"
        />
      </div>
      <ChartChangeLegend
        type={_.isBoolean(isPositiveBetter) ? "default" : "neutral"}
      />
    </div>
  );
}

ChartTreemap.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isPositiveBetter: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  decimalPlaces: PropTypes.number.isRequired,
  changeThreshold: PropTypes.number.isRequired,
  labelThreshold: PropTypes.number,
};

ChartTreemap.defaultProps = {
  labelThreshold: 10,
};
