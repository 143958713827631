import ChartChangeLegend from "./ChartChangeLegend";
import chartConfig, { fontSizes } from "./chartConfig";
import { COLOR_SAME } from "./colors";
import { generateChangeData } from "./utils";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartPie({
  data,
  isPositiveBetter,
  changeThreshold,
  decimalPlaces,
  isLoading,
  labelThreshold,
}) {
  const changes = generateChangeData(data, isPositiveBetter, changeThreshold);
  return (
    <>
      <ApexChart
        options={chartConfig({
          dataLabels: {
            formatter: (value) => {
              if (value < labelThreshold) {
                return "";
              }
              return `${_.round(value)}%`;
            },
            style: {
              fontSize: fontSizes.medium,
            },
          },
          colors: _.isBoolean(isPositiveBetter)
            ? _.map(changes, "color")
            : _.times(changes.length, () => COLOR_SAME),
          labels: _.map(changes, "label"),
          tooltip: {
            y: {
              formatter: (value) => `${_.round(value, decimalPlaces)}%`,
            },
            fillSeriesColor: false,
          },
          legend: {
            show: false,
          },
        })}
        series={isLoading ? [] : changes.map(({ percent }) => percent)}
        type="pie"
      />
      <ChartChangeLegend
        type={_.isBoolean(isPositiveBetter) ? "default" : "neutral"}
      />
    </>
  );
}

ChartPie.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isPositiveBetter: PropTypes.bool,
  decimalPlaces: PropTypes.number.isRequired,
  changeThreshold: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  labelThreshold: PropTypes.number,
};

ChartPie.defaultProps = {
  labelThreshold: 10,
};
