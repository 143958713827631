import ContactUs from "./ContactUs";
import "./Footer.scss";
import TermsOfUseModal from "./TermsOfUseModal";
import interactions from "./interactions";

const partners = [
  {
    image: "logo-jcu-agtac.jpg",
    link: "https://www.jcu.edu.au/agtac",
    text: "AgTAC",
  },
  {
    image: "logo-agritech-solutions.png",
    link: "https://agritechsolutions.com.au/",
    text: "Agritech Solutions",
  },
  {
    image: "logo-gbrf-a.png",
    link: "https://www.barrierreef.org/",
    text: "Great Barrier Reef Fund",
  },
  {
    image: "logo-gbrf-b.png",
    link: "https://www.barrierreef.org/",
    text: "Great Barrier Reef Fund",
  },
];

const linkClasses = "btn btn-link text-decoration-none py-0 px-2 px-md-3";

export default function Footer() {
  return (
    <footer>
      <div className="d-flex align-items-center flex-wrap justify-content-evenly">
        {partners.map((item) => (
          <div key={item.image} className="p-3">
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                interactions.publish({
                  category: "Footer",
                  action: "opened",
                  label: item.text,
                })
              }
            >
              <img
                className="irat-footer__image"
                src={`/footer/${item.image}`}
                alt={item.text}
                title={item.text}
              />
            </a>
          </div>
        ))}
      </div>
      <hr />
      <p className="mb-0 text-center">
        <TermsOfUseModal>
          {(show) => (
            <button className={linkClasses} onClick={show}>
              Terms of use
            </button>
          )}
        </TermsOfUseModal>
        {"|"}
        <ContactUs>
          {(show) => (
            <span onClick={show} role="button" className={linkClasses}>
              Contact us
            </span>
          )}
        </ContactUs>
      </p>
    </footer>
  );
}
