import Help from "./Help";
import SummaryCard from "./SummaryCard";
import { scaleData } from "./api";
import {
  VIEW_BASIC_SUMMARIES,
  VIEW_ADVANCED_SUMMARIES,
  VIEW_EXPERIMENTAL_SUMMARIES,
} from "./auth";
import * as fieldIds from "./fieldIds";
import fields from "./fields";
import { greenhouseGasEmission } from "./helps";
import {
  PADDOCK_SIZE,
  DRIP_OPERATION_COST,
  SURFACE_OPERATION_COST,
} from "./settingIds";
import { calculateRelativeChange } from "./utils";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import PropTypes from "proptypes";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const generateSummaryItem = (args, stats, settings, scenarios) => {
  const parameters = {
    useRelative: false,
    decimalPlaces: 1,
    ...args,
  };
  const field = fields[parameters.fieldId];
  const result = {
    direction: false,
    theme: "loading",
    value: "",
    name: args.nameOverride || field.name,
    icon: field.icon,
  };
  if (field.description) {
    result.tooltip = {
      text: field.description,
      url: field.url,
    };
  }
  if (!stats) {
    return result;
  }
  result.theme = "same";
  const means = stats.mean[parameters.fieldId];
  const scaled = scaleData(stats, settings[PADDOCK_SIZE]).mean;
  const scaledMeans = scaled[parameters.fieldId];
  const relative = calculateRelativeChange(means.new, means.current);
  let formatted;
  if (parameters.useRelative) {
    const relativeNumber = relative * 100;
    const roundedRelativeNumber = _.round(
      relativeNumber,
      _.round(relativeNumber) > 100 ? 0 : parameters.decimalPlaces
    );
    formatted = {
      value: `${Math.abs(roundedRelativeNumber)} %`,
      sign: Math.sign(roundedRelativeNumber),
    };
  } else {
    formatted = field.format(scaledMeans.new - scaledMeans.current);
    if (settings[PADDOCK_SIZE] !== 1) {
      result.perHaValue = field.format(
        means.new - stats.mean[parameters.fieldId].current
      ).value;
    }
  }
  result.value = formatted.value;
  if (formatted.sign === 1) {
    result.direction = "up";
  } else if (formatted.sign === -1) {
    result.direction = "down";
  }
  const isPositiveChangeBetter = field.isPositiveChangeBetter({ scenarios });
  if (_.isUndefined(isPositiveChangeBetter)) {
    result.theme = "neutral";
  } else if (result.direction) {
    if (relative > field.changeThreshold) {
      result.theme = isPositiveChangeBetter ? "better" : "worse";
    } else if (relative < -field.changeThreshold) {
      result.theme = isPositiveChangeBetter ? "worse" : "better";
    }
  }
  result.theme = args.themeOverride || result.theme;
  return result;
};

const hasOperationCostFilter = (settings) =>
  settings[DRIP_OPERATION_COST] !== 0 || settings[SURFACE_OPERATION_COST] !== 0;

export const summarySections = [
  {
    title: "Finance",
    items: [
      {
        fieldId: fieldIds.DD_TOTAL_COST,
        permission: VIEW_ADVANCED_SUMMARIES,
        filter: hasOperationCostFilter,
      },
      {
        fieldId: fieldIds.DD_WATER_COST,
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        fieldId: fieldIds.DD_TOTAL_IRRIGATION_OPERATION_COST,
        permission: VIEW_ADVANCED_SUMMARIES,
        filter: hasOperationCostFilter,
      },
      {
        fieldId: fieldIds.DD_TOTAL_ENERGY_COST,
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        fieldId: fieldIds.DD_SUGAR_VALUE,
        permission: VIEW_ADVANCED_SUMMARIES,
      },
      {
        fieldId: fieldIds.DD_NET_INCOME,
        permission: VIEW_ADVANCED_SUMMARIES,
      },
    ],
  },
  {
    title: "Water and energy",
    items: [
      {
        fieldId: fieldIds.DF_TOTAL_IRRIGATION,
        nameOverride: "Irrigation water",
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        fieldId: fieldIds.DD_TOTAL_ENERGY_USED,
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        useRelative: true,
        fieldId: fieldIds.DF_TOTAL_EFF_RAIN,
        permission: VIEW_ADVANCED_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_IRRIGATION_NUMBER,
        permission: VIEW_BASIC_SUMMARIES,
        themeOverride: "unknown",
      },
    ],
  },
  {
    title: "Water quality",
    items: [
      {
        fieldId: fieldIds.DF_TOTAL_N_POL,
        nameOverride: "DIN loss",
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_NO3_RUNOFF,
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_NO3_LEACH,
        permission: VIEW_BASIC_SUMMARIES,
      },
    ],
  },
  {
    title: "Crop status",
    items: [
      {
        fieldId: fieldIds.DF_TOTAL_TRANSPIRATION,
        permission: VIEW_ADVANCED_SUMMARIES,
        themeOverride: "unknown",
      },
      {
        useRelative: true,
        fieldId: fieldIds.DF_TE,
        permission: VIEW_ADVANCED_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_N_UPTAKE,
        permission: VIEW_ADVANCED_SUMMARIES,
      },
    ],
  },
  {
    title: "Soil status",
    items: [
      {
        fieldId: fieldIds.DF_TOTAL_NO3_DENITRIFICATION,
        nameOverride: "Denitrification loss",
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_NITRIFICATION,
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_SOIL_C,
        permission: VIEW_EXPERIMENTAL_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_SOIL_N,
        permission: VIEW_EXPERIMENTAL_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_SOIL_INORG_N,
        permission: VIEW_EXPERIMENTAL_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_SOIL_ORG_N,
        permission: VIEW_EXPERIMENTAL_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_SEDMINENT,
        permission: VIEW_EXPERIMENTAL_SUMMARIES,
      },
    ],
  },
  {
    title: "Productivity",
    items: [
      {
        useRelative: true,
        fieldId: fieldIds.DF_GPWUI,
        nameOverride: "GPWUI",
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        useRelative: true,
        fieldId: fieldIds.DF_NUE,
        permission: VIEW_ADVANCED_SUMMARIES,
      },
      {
        useRelative: true,
        fieldId: fieldIds.DF_NUPE,
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        useRelative: true,
        fieldId: fieldIds.DD_NITROGEN_LOSS_RISK_INDEX,
        permission: VIEW_ADVANCED_SUMMARIES,
      },
      {
        useRelative: true,
        decimalPlaces: 0,
        fieldId: fieldIds.DF_CANE_FW,
        permission: VIEW_BASIC_SUMMARIES,
      },
      {
        useRelative: true,
        decimalPlaces: 0,
        fieldId: fieldIds.DF_SUCROSE_WEIGHT,
        permission: VIEW_EXPERIMENTAL_SUMMARIES,
      },
      {
        useRelative: true,
        decimalPlaces: 0,
        fieldId: fieldIds.DF_BIOMASS,
        permission: VIEW_EXPERIMENTAL_SUMMARIES,
      },
      {
        useRelative: true,
        decimalPlaces: 0,
        fieldId: fieldIds.DF_BIOMASS_GREEN,
        permission: VIEW_EXPERIMENTAL_SUMMARIES,
      },
    ],
  },
  {
    title: "Greenhouse gas (GHG) emission",
    help: greenhouseGasEmission,
    items: [
      {
        fieldId: fieldIds.DD_TOTAL_CO2_E,
        nameOverride: "Total GHG",
        permission: VIEW_ADVANCED_SUMMARIES,
      },
      {
        fieldId: fieldIds.DF_TOTAL_SOIL_CO2,
        nameOverride: "Soil CO₂",
        permission: VIEW_ADVANCED_SUMMARIES,
      },
      {
        fieldId: fieldIds.DD_TOTAL_CH4_BURNT_GHG,
        nameOverride: "Methane (CH₄)",
        permission: VIEW_ADVANCED_SUMMARIES,
      },
      {
        fieldId: fieldIds.DD_TOTAL_PUMP_CO2,
        nameOverride: "Pump",
        permission: VIEW_ADVANCED_SUMMARIES,
      },
      {
        fieldId: fieldIds.DD_TOTAL_N2O_ALL,
        permission: VIEW_ADVANCED_SUMMARIES,
      },
    ],
  },
];

export default function Summary({
  checkPermission,
  settings,
  data,
  isLoading,
  scenarios,
}) {
  const summarySectionItems = summarySections
    .map(({ title, help, items }) => ({
      title,
      help,
      items: items.filter((item) => checkPermission(item.permission)),
    }))
    .filter(({ items }) => items.length > 0)
    .map(({ title, help, items }) => (
      <div key={title} className="my-5">
        <h3 className="mb-3 h4">
          {title}
          {!!help && <Help help={help} />}
        </h3>
        <div className="mb-n4">
          <div className="row">
            {items
              .filter(
                (item) => !_.isFunction(item.filter) || item.filter(settings)
              )
              .map((item) => {
                const summary = generateSummaryItem(
                  item,
                  data.stats,
                  settings,
                  scenarios
                );
                return (
                  <div
                    key={summary.name}
                    className="col-xxl-3 col-xl-4 col-sm-6 mb-4"
                  >
                    <SummaryCard {...summary} isLoading={isLoading} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    ));
  return (
    <section>
      <p className="lead text-secondary">
        {`Summary of changes (averaged across ${
          _.values(data.years).length
        } seasons over ${settings[PADDOCK_SIZE]} ha)`}
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          overlay={
            <Tooltip>
              Simulated change in finance, water and energy consumption,
              productivity, and environmental impacts by moving from the{" "}
              <b>Current</b> scenario to the <b>New</b> scenario.
            </Tooltip>
          }
        >
          <FontAwesomeIcon
            role="button"
            icon={faQuestionCircle}
            className="ms-2 text-dark"
          />
        </OverlayTrigger>
      </p>
      <hr />
      <div className="my-n5 pt-1 overflow-hidden">{summarySectionItems}</div>
    </section>
  );
}

Summary.propTypes = {
  settings: PropTypes.shape({
    paddockSize: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  checkPermission: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scenarios: PropTypes.shape({}).isRequired,
};

Summary.defaultProps = {
  data: false,
};
