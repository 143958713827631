export const CANE_PRICE_CONSTANT = "canePriceConstant";
export const DRIP_ENERGY_USED = "dripEnergyUsed";
export const DRIP_OPERATION_COST = "dripOperationCost";
export const DRY_OFF_DAYS = "dryOffDays";
export const ENERGY_COST = "energyCost";
export const FALLOW = "fallow";
export const FERTILISER_AMOUNT = "fertiliserAmount";
export const FERTILISER_DISTRIBUTION = "fertiliserDistribution";
export const FUEL = "fuel";
export const HARVEST_LOSS = "harvestLoss";
export const HARVEST_YEARS = "harvestYears";
export const IRRIGATION_ALLOCATION = "irrigationAllocation";
export const IRRIGATION_AMOUNT = "irrigationAmount";
export const IRRIGATION_FREQUENCY = "irrigationFrequency";
export const IRRIGATION_SYSTEM = "irrigationSystem";
export const IS_BURNT = "isBurnt";
export const PADDOCK_SIZE = "paddockSize";
export const PLANTING = "planting";
export const RATOON = "ratoon";
export const REGION = "region";
export const RELATIVE_CCS = "relativeCcs";
export const SECONDARY_FERTILISER_DISTRIBUTION =
  "secondaryFertiliserDistribution";
export const SOIL = "site";
export const SUGAR_PRICE = "sugarPrice";
export const SURFACE_ENERGY_USED = "surfaceEnergyUsed";
export const SURFACE_OPERATION_COST = "surfaceOperationCost";
export const TILLAGE = "tillage";
export const WATER_COST = "waterCost";
export const WEATHER_STATION = "weatherStation";
