import FixedContainer from "./FixedContainer";
import chartConfig, { yAxisLabelMinWidth } from "./chartConfig";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartWeatherLongTermAverages({ series, unit, format }) {
  const seriesData = [];
  series.forEach((seriesItem) => {
    const data = seriesItem.data;
    if (data.length) {
      seriesData.push({
        name: seriesItem.name,
        type: "bar",
        data,
      });
    }
  });
  return (
    <FixedContainer>
      <ApexChart
        type="bar"
        options={chartConfig({
          dataLabels: {
            enabled: false,
          },
          colors: _.map(series, "color"),
          xaxis: {
            type: "datetime",
            title: {
              text: "Month",
            },
            labels: {
              format: "MMM",
            },
          },
          tooltip: {
            x: {
              format: "MMMM",
            },
            y: {
              formatter: (value) => format(value).value,
            },
          },
          yaxis: {
            title: {
              text: unit,
            },
            decimalsInFloat: 0,
            labels: {
              minWidth: yAxisLabelMinWidth,
            },
            min: 0,
          },
          grid: {
            show: false,
          },
          legend: {
            customLegendItems: _.map(series, "name"),
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          plotOptions: {
            bar: {
              columnWidth: series.length > 1 ? "80%" : "60%",
            },
          },
        })}
        series={seriesData}
        height="100%"
      />
    </FixedContainer>
  );
}

ChartWeatherLongTermAverages.propTypes = {
  unit: PropTypes.string.isRequired,
  format: PropTypes.func.isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
          y: PropTypes.number,
        })
      ).isRequired,
    })
  ).isRequired,
};
