import TermsOfUseModal from "./TermsOfUseModal";
import PropTypes from "proptypes";
import React from "react";
import Modal from "react-bootstrap/Modal";

export default function DisclaimerGate({ show, setShow }) {
  return (
    <Modal scrollable centered backdrop="static" show={show}>
      <Modal.Header>
        <Modal.Title>Disclaimer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>By accepting, you understand that:</strong>
        </p>
        <ul>
          <li>
            We strongly recommend that information provided by i-RAT should be
            used in close consultation with the appropriate extension
            professional or agronomic advisor that has been trained by our
            officers and/or officers of Sugar Research Australia.
          </li>
          <li>
            While every care is taken to ensure the relevance of this
            information, we provide no warranties, whether expressed or implied,
            as to i-RAT’s accuracy, currency, availability, reliability,
            quality, completeness, or suitability for any particular purpose.
          </li>
          <li>
            You release us from all responsibility and liability (including
            liability for breach of contract or negligence) for all direct,
            indirect, consequential, or other expenses, losses, damages, and
            costs which might be incurred because of the
            information/recommendations being inaccurate or incomplete in any
            way and for any reason.
          </li>
          <li>
            We are not responsible nor liable for any damage to, or viruses that
            may infect, your computer equipment on account of your access to,
            use of, or browsing in the application or your downloading of any
            materials, data, text, images, video, or audio from the application.
          </li>
        </ul>
        <p>
          <strong>
            By accepting, you agree to our{" "}
            <TermsOfUseModal>
              {(show) => (
                <button
                  className="btn btn-link text-decoration-none p-0"
                  onClick={show}
                >
                  terms of use
                </button>
              )}
            </TermsOfUseModal>
            .
          </strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-dark" onClick={() => setShow(false)}>
          Accept
        </button>
      </Modal.Footer>
    </Modal>
  );
}

DisclaimerGate.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
