import PropTypes from "proptypes";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

export default function ConfirmerModal({ children, title, body, onConfirm }) {
  const [show, setShow] = useState(false);
  const childExtended = React.cloneElement(children, {
    onClick: (e) => {
      e.stopPropagation();
      setShow(true);
    },
    role: "button",
  });
  return (
    <>
      {childExtended}
      <Modal
        size="sm"
        scrollable
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-dark"
            onClick={() => {
              setShow(false);
              onConfirm();
            }}
          >
            Ok
          </button>
          <button
            className="btn btn-outline-dark"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ConfirmerModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
};
