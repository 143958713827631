import PropTypes from "prop-types";
import { useState, useEffect } from "react";

const breakpoints = {
  lg: 992,
  md: 768,
  sm: 576,
  xl: 1200,
  xxl: 1400,
  xs: 0,
};

export default function Responsive({ above, below, children }) {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  let isShowing;
  if (above) {
    isShowing = width >= breakpoints[above];
  }
  if (below) {
    isShowing = width < breakpoints[below];
  }
  return isShowing ? children : null;
}

Responsive.propTypes = {
  above: PropTypes.string,
  below: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Responsive.defaultProps = {
  above: "",
  below: "",
};
