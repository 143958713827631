import "./PaddockSettings.scss";
import interactions from "./interactions";
import _ from "lodash";
import PropTypes from "proptypes";

export default function PaddockSettings({
  settings,
  checkPermission,
  values,
  onChange,
}) {
  return (
    <div className="row g-3 align-items-end">
      {settings
        .filter(
          (setting) =>
            !_.isFunction(setting.isVisible) ||
            setting.isVisible(_.first(_.values(values)).settings)
        )
        .filter((setting) => checkPermission(setting.permission))
        .map((setting) => (
          <setting.render
            key={setting.id}
            className={`col-lg-${_.defaultTo(
              setting.cols,
              4
            )} col-md-${_.defaultTo(setting.cols, 6)} mb-1`}
            values={_.first(_.values(values)).settings}
            scenarios={values}
            onChange={(newValues, label) => {
              interactions.publish({
                category: "Paddock settings",
                action: `changed ${setting.id}`,
                label,
              });
              onChange(newValues);
            }}
          />
        ))}
    </div>
  );
}

PaddockSettings.propTypes = {
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  checkPermission: PropTypes.func.isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
