// Data Fields.
export const DF_BIOMASS = "biomass";
export const DF_BIOMASS_GREEN = "green_biomass";
export const DF_BURNT_DM = "burntDm";
export const DF_BURNT_ORG_C = "burntOrgC";
export const DF_BURNT_ORG_N = "burntOrgN";
export const DF_CANE_FW = "caneFw";
export const DF_CANE_WEIGHT = "caneWeight";
export const DF_CROP_CYCLE = "cropCycle";
export const DF_DAY = "day";
export const DF_GPWUI = "gpwui";
export const DF_IRRIGATION_AMOUNT = "irrigationAmount";
export const DF_IRRIGATION_NUMBER = "irrigationNumber";
export const DF_NUE = "nue";
export const DF_NUPE = "nupe";
export const DF_PLANTING = "planting";
export const DF_RATOON = "ratoon";
export const DF_REAL_DELAY_FOR_KILL = "realDelayForKill";
export const DF_SUCROSE_WEIGHT = "sucroseWeight";
export const DF_SUGAR_HARVEST_DELAY = "sugarHarvestDelay";
export const DF_SUGAR_HARVEST_YEAR = "sugarHarvestYear";
export const DF_SUGAR_PLANT_DELAY = "sugarPlantDelay";
export const DF_SUGAR_PLANT_YEAR = "sugarPlantYear";
export const DF_SUGAR_STATUS = "sugarStatus";
export const DF_SURFACE_OM_WT = "surfaceOmWt";
export const DF_TE = "te";
export const DF_TILLAGE = "tillage";
export const DF_TOTAL_DRAINAGE = "totalDrainage";
export const DF_TOTAL_EVAPORATION = "totalEvaporation";
export const DF_TOTAL_EVAPOTRANSPIRATION = "totalEvapotranspiration";
export const DF_TOTAL_IRRIGATION = "totalIrrigation";
export const DF_TOTAL_N_G_CANE = "totalNgCane";
export const DF_TOTAL_N_LOSS = "totalNLoss";
export const DF_TOTAL_N_POL = "totalNPol";
export const DF_TOTAL_N_UPTAKE = "totalNUptake";
export const DF_TOTAL_NITRIFICATION = "totalNitrification";
export const DF_TOTAL_SOIL_CO2 = "totalSoilCO2";
export const DF_TOTAL_SOIL_N2O = "totalSoilN2O";
export const DF_TOTAL_NO3_DENITRIFICATION = "totalNo3Denitrification";
export const DF_TOTAL_NO3_LEACH = "totalNo3Leach";
export const DF_TOTAL_NO3_RUNOFF = "totalNo3Runoff";
export const DF_TOTAL_RAIN = "totalRain";
export const DF_TOTAL_EFF_RAIN = "totalEffRain";
export const DF_TOTAL_RUNOFF = "totalRunoff";
export const DF_TOTAL_SEDMINENT = "totalSediment";
export const DF_TOTAL_INITIAL_INORG_N = "totalInitInorgN";
export const DF_TOTAL_INITIAL_ORG_N = "totalInitOrgN";
export const DF_TOTAL_INITIAL_SOIL_N = "totalInitSoilN";
export const DF_TOTAL_SOIL_C = "totalSoilC";
export const DF_TOTAL_SOIL_INORG_N = "totalSoilInorgN";
export const DF_TOTAL_SOIL_ORG_N = "totalSoilOrgN";
export const DF_TOTAL_SOIL_N = "totalSoilN";
export const DF_TOTAL_TRANSPIRATION = "totalTranspiration";

// Derived Data fields.
export const DD_DELTA_SOIL_INORG_N = "deltaSoilInorgN";
export const DD_DELTA_SOIL_ORG_N = "deltaSoilOrgN";
export const DD_DELTA_SOIL_N = "deltaSoilN";
export const DD_NET_INCOME = "netIncome";
export const DD_NITROGEN_LOSS_RISK_INDEX = "nitrogenLossRiskIndex";
export const DD_SUGAR_VALUE = "sugarValue";
export const DD_TOTAL_COST = "totalCost";
export const DD_TOTAL_CO2_E = "totalCo2E";
export const DD_TOTAL_CH4_BURNT_GHG = "totalCh4BurntGhg";
export const DD_TOTAL_ENERGY_USED = "totalEnergyUsed";
export const DD_TOTAL_ENERGY_COST = "totalEnergyCost";
export const DD_TOTAL_IRRIGATION_OPERATION_COST =
  "totalIrrigationOperationCost";
export const DD_TOTAL_N2O_ALL = "totalN2OAll";
export const DD_TOTAL_N2O_BURNT = "totalN2OBurnt";
export const DD_TOTAL_N2O_BURNT_GHG = "totalN2OBurntGhg";
export const DD_TOTAL_N2O_GHG = "totalN2OGhg";
export const DD_TOTAL_PUMP_CO2 = "totalPumpCo2";
export const DD_TOTAL_SOIL_N = "totalSoilN";
export const DD_WATER_COST = "waterCost";
