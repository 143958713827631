import "./ScenarioSettings.scss";
import interactions from "./interactions";
import { scenarioSettings } from "./settings";
import _ from "lodash";
import PropTypes from "proptypes";

export default function ScenarioSettings({ name, values, onChange }) {
  return (
    <div className="irat-scenario-settings">
      <h4>{name}</h4>
      {scenarioSettings
        .filter(
          (setting) =>
            !_.isFunction(setting.isVisible) ||
            setting.isVisible(_.first(_.values(values)).settings)
        )
        .map((setting) => (
          <setting.render
            key={setting.id}
            className="mb-3"
            values={_.first(_.values(values)).settings}
            scenarios={values}
            onChange={(newValues, label) => {
              interactions.publish({
                category: "Scenario settings",
                action: `changed ${setting.id}`,
                label,
              });
              onChange(newValues);
            }}
          />
        ))}
    </div>
  );
}

ScenarioSettings.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};
