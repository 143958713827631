import Modal from "./Modal";
import PropTypes from "proptypes";
import { useState } from "react";

export default function TermsOfUseModal({ children }) {
  const [show, setShow] = useState(false);
  return (
    <>
      {children(() => setShow(true))}
      <Modal size="xl" scrollable show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms of use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This page sets out the terms of use of this application, hereafter
            referred to as ‘i-RAT’ (Irrigation Rapid Assessment Tool). We
            reserve the right to update or amend these Terms of Use at any time.
            By using this application, you accept the Terms of Use as they apply
            from time to time. You should check this page from time to time to
            make sure you are aware of any changes. A reference in these Terms
            of Use to ‘we’, ‘us’ or ‘our’ means James Cook University.
          </p>
          <h4>What is i-RAT</h4>
          <p>
            i-RAT (Irrigation Rapid Assessment Tool) is a web-based decision
            support tool developed by James Cook University. The purpose of this
            product is to aid sugarcane farmers and extension officers to assess
            the potential impact of changing farm practices. Rescaling this
            information beyond one or several paddocks, or use of it for
            purposes other than its intended use is not recommended. It is
            strongly recommended that information provided by i-RAT should be
            used in close consultation with the appropriate extension
            professional or agronomic advisor that have been trained by the
            officers of James Cook University and/or Sugar Research Australia.
          </p>
          <h4>Copyright and trademark notice</h4>
          <p>
            The content provided by us on this application is owned or licenced
            by us and is protected by copyright laws in Australia and overseas.
            You may only use the content for your own personal purposes, and may
            not otherwise use, copy, reproduce, modify, publish, or transmit the
            content without our prior written consent. This application includes
            trademarks which are protected by law. You may not use our
            trademarks in Australia or internationally without our prior written
            consent, except to legitimately identify our products or services.
          </p>
          <h4>Liability</h4>
          <p>
            Neither James Cook University nor its affiliate’s nor any of its
            Council members, officers, employees, agents warrant that use of the
            application will be uninterrupted or error-free. The application is
            provided on an “as is” and “as available” basis. The outputs are
            from available modelling that enable estimation of the potential
            impact of changing farm practices. While the modelling has been
            conducted to represent Burdekin sugarcane farming systems, results
            are not directly associated to any unique combination of soil,
            climate, and farming practices. Rescaling this information beyond
            one or several paddocks, or use of it for purposes other than its
            intended use is not recommended.
          </p>
          <p>
            We strongly recommend that information provided by i-RAT should be
            used in close consultation with the appropriate extension
            professional or agronomic advisor that have been trained by our
            officers and/or the officers of Sugar Research Australia (SRA).
          </p>
          <p>
            Use of this application is at your sole risk. While every care is
            taken to ensure the relevance of this information, we disclaim all
            warranties, whether expressed or implied, as to i-RAT’s accuracy,
            currency, availability, reliability, quality, completeness, or
            suitability for any particular purpose.
          </p>
          <p>
            We disclaim all responsibility and liability (including without
            limitation, liability in negligence) to any person or entity for all
            direct, indirect, consequential, or other expenses, losses, damages
            (including indirect or consequential damage) and costs which might
            be incurred because of the information/recommendations being
            inaccurate or incomplete in any way and for any reason.
          </p>
          <p>
            If any law implies a condition or warranty into these Terms of Use
            which cannot lawfully be excluded, then, to the maximum extent
            permitted by law, our liability for any breach of such implied term
            will be limited to the supply of the relevant service again or the
            payment of the cost of having that service re-supplied.
          </p>
          <p>
            We assume no responsibility, and shall not be liable for, any damage
            to, or viruses that may infect, your computer equipment on account
            of your access to, use of, or browsing in the application or your
            downloading of any materials, data, text, images, video, or audio
            from the application.
          </p>
          <h4>Linking or framing</h4>
          <p>
            This application may contain links to, information from, or
            references to third party sites and may on occasion display content
            or information from third party sites within frames on this
            application. For example:
          </p>
          <ol>
            <li>
              historical weather data is based on Australian Bureau of
              Meteorology information; and
            </li>
            <li>
              climatological and observed weather data are sourced from SILO
              (longpaddock.qld.gov.au/silo/point-data) provided by Queensland
              Department of Environment and Science; and
            </li>
            <li>
              soil data is obtained from various data sources including but not
              limited to:
            </li>
            <ul>
              <li>APSoil (https://www.apsim.info/apsim-model/apsoil);</li>
              <li>Donnollan, 1991;</li>
              <li>Attard et al., 2009;</li>
              <li>Hesp et al., 2011.</li>
            </ul>
          </ol>
          <p>
            We are not responsible or liable for the content of those sites, and
            do not warrant the accuracy, currency, or suitability for any
            purpose of that information. Any links are provided for convenience
            only, and do not indicate any endorsement or recommendation by us.
            Your access to and use of those sites is at your own risk. You may
            link to this application provided you do so in a way that accurately
            indicates that the link is to i-RAT and is not misleading.{" "}
          </p>
          <h4>Misuse of application</h4>
          <p>
            You agree to only use this application in accordance with these
            Terms of Use and applicable laws. You may be held personally liable
            for any unlawful use of this application. If you think any content
            on this application is inappropriate or unlawful or infringes your
            rights, please contact us via email at{" "}
            <a
              href="mailto:brian.collins@jcu.edu.au?Subject=From%20iRAT%20App"
              target="_blank"
              rel="noreferrer"
            >
              brian.collins@jcu.edu.au
            </a>
            .
          </p>
          <h4>Privacy</h4>
          <p>
            Any personal information collected by us through your use of this
            application will be handled in accordance with our obligations under
            applicable privacy laws, including the{" "}
            <i>Information Privacy Act 2009</i>
            (Qld).
          </p>
          <h4>Security</h4>
          <p>
            The transmission of information over the Internet is not completely
            secure or error-free. Information submitted to or accessed via this
            application may not be secure and you should use discretion in
            deciding what information you provide to us via these means.
          </p>
          <h4>Cookies</h4>
          <p>
            We may use cookies to identify your computer on our server and so we
            can track your use of this application. In some instances, cookies
            may collect and store personal information about you. Such personal
            information will only be used by us in accordance with our
            obligations under applicable privacy laws.
          </p>
          <h4>Governing law</h4>
          <p>
            The terms of this application will be governed by and construed in
            accordance with the laws in force in Queensland, and you
            unconditionally submit to the non - exclusive jurisdiction of the
            courts of Queensland. Although Australian websites may be accessed
            outside of Australia, we make no representation that the content
            complies with the laws of any other country. If you access this
            application from outside Australia, you are solely responsible for
            ensuring compliance with your local laws and for any reliance on our
            application content.
          </p>
          <h4>References</h4>
          <ul>
            <li>
              Attard, S.J., Inman-Bamber, N.G., Hesp, C., 2009. Use of overhead
              irrigation in the lower Burdekin uses less water and does not
              penalise yield, in: 31st Annual Australian Society of Sugar Cane
              Technologists Conference.
            </li>
            <li>
              Donnollan, T.E., 1991. Understanding and managing Burdekin (BRIA)
              soils.
            </li>
            <li>
              Hesp, C., Attard, S., Mc Shane, T., Poggio, M., Page, J., 2011.
              Evaluating alternative irrigation for a greener future (A
              Collaborative Project Conducted by the MAFIA grower group, CSIRO
              Ecosystem Sciences, BBIFMAC, DEEDI).
            </li>
          </ul>
          <p className="text-end">
            <i>Last updated on 06/10/2022</i>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-dark"
            onClick={() => setShow(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

TermsOfUseModal.propTypes = {
  children: PropTypes.func.isRequired,
};
