import PropTypes from "proptypes";
import React from "react";

const FixedContainer = ({ className, children, minHeight, ratio }) => (
  <div
    className={`${className} position-relative`}
    style={{
      minHeight: `${minHeight}px`,
      paddingTop: `${100 / ratio}%`,
    }}
  >
    <div className="position-absolute top-0 left-0 w-100 h-100">{children}</div>
  </div>
);

FixedContainer.propTypes = {
  children: PropTypes.node.isRequired,
  minHeight: PropTypes.number,
  ratio: PropTypes.number,
  className: PropTypes.string,
};

FixedContainer.defaultProps = {
  minHeight: 300,
  ratio: 1.618,
  className: "",
};

export default FixedContainer;
