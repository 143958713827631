import "./ChartContainer.scss";
import PropTypes from "proptypes";
import React from "react";

const ChartContainer = ({ title, subtitle, children }) => {
  return (
    <div className="irat-chart-container col-lg-6 col-xxl-4 mb-5 overflow-hidden">
      <h3 className="text-center h4">{title}</h3>
      <ul className="text-center list-unstyled mb-0 text-secondary">
        {subtitle.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
      {children}
    </div>
  );
};

ChartContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  children: PropTypes.node.isRequired,
};

export default ChartContainer;
