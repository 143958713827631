import "./Introduction.scss";
import {
  faClipboardQuestion,
  faGear,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const featureList = [
  {
    id: "What",
    title: (
      <>
        What is{" "}
        <span className="text-primary">
          <span className="text-lowercase">i</span>-RAT
        </span>
        ?
      </>
    ),
    icon: faGear,
    description: (
      <p className="mb-0">
        i-RAT (Irrigation Rapid Assessment Tool) is an interactive web-based app
        that unravels complex interactions between crop, soil, climate, and
        management.
      </p>
    ),
  },
  {
    id: "Why",
    title: (
      <>
        Why use{" "}
        <span className="text-primary">
          <span className="text-lowercase">i</span>-RAT
        </span>
        ?
      </>
    ),
    icon: faClipboardQuestion,
    description: (
      <p className="mb-0">
        i-RAT compares the economic and environmental outcomes from many
        different irrigation scenarios that would be impossible to trial in the
        field.
      </p>
    ),
  },
  {
    id: "Who",
    title: (
      <>
        Who is{" "}
        <span className="text-primary">
          <span className="text-lowercase">i</span>-RAT
        </span>{" "}
        for?
      </>
    ),
    icon: faUser,
    description: (
      <p className="mb-0">
        i-RAT has been developed for farmers, extension officers and agronomic
        advisors but, due to its ability to assess environmental impacts, may
        also be useful for banks and eco-investors.
      </p>
    ),
  },
];

export default function Introduction() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          {featureList.map((item) => (
            <div
              key={item.id}
              className="d-flex mt-3 mb-5 align-items-center flex-md-row flex-column"
            >
              <FontAwesomeIcon
                icon={item.icon}
                fixedWidth
                className="text-primary flex-grow-0 flex-shrink-0 py-3"
                size="6x"
              />
              <div className="vr mx-3 d-none d-md-block" />
              <div className="py-3 ps-2">
                <h3 className="text-center text-secondary text-md-start text-uppercase fw-bolder">
                  {item.title}
                </h3>
                {item.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
