import App from "./App";
import Auth from "./AuthGate";
import ErrorBoundary, { TestError } from "./ErrorBoundary";
import Pdf from "./Pdf";
import { COLOR_PRIMARY } from "./colors";
import { getModalCountFromParams } from "./getModalCountFromParams";
import "./index.scss";
import interactions from "./interactions";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";

if (getModalCountFromParams() > 0) {
  window.history.replaceState(null, null, `./`);
}

Amplify.configure({
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
});

const root = createRoot(document.getElementById("root"));

let content;
if (window.location.pathname === "/pdf") {
  content = <Pdf />;
} else if (window.location.pathname === "/error") {
  content = <TestError />;
} else {
  content = (
    <Auth>
      <App />
    </Auth>
  );
}

root.render(
  <React.StrictMode>
    <ErrorBoundary>{content}</ErrorBoundary>
  </React.StrictMode>
);

if (process.env.REACT_APP_ANALYTICS_ENABLED === "true") {
  ReactGA.initialize("UA-242773206-1", {
    debug: process.env.REACT_APP_DEBUG_ANALYTICS === "true",
  });
  ReactGA.pageview("/");
  interactions.subscribe(ReactGA.event);
}
if (process.env.NODE_ENV === "development") {
  interactions.subscribe((interaction) =>
    console.info("%cInteraction:", `color: ${COLOR_PRIMARY}`, interaction)
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
