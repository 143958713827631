import * as constants from "./constants";
import _ from "lodash";

export const CHANGE_BASIC_SETTINGS = "CHANGE_BASIC_SETTINGS";
export const CHANGE_ADVANCED_SETTINGS = "CHANGE_ADVANCED_SETTINGS";
export const VIEW_BASIC_TABS = "VIEW_BASIC_TABS";
export const VIEW_ADVANCED_TABS = "VIEW_ADVANCED_TABS";
export const VIEW_EXPERIMENTAL_TABS = "VIEW_EXPERIMENTAL_TABS";
export const VIEW_BASIC_CHART = "VIEW_BASIC_CHART";
export const VIEW_BASIC_CHART_TYPES = "VIEW_BASIC_CHART_TYPES";
export const VIEW_ADVANCED_CHART = "VIEW_ADVANCED_CHART";
export const VIEW_ADVANCED_CHART_TYPES = "VIEW_ADVANCED_CHART_TYPES";
export const VIEW_EXPERIMENTAL_CHART = "VIEW_EXPERIMENTAL_CHART";
export const VIEW_EXPERIMENTAL_CHART_TYPES = "VIEW_EXPERIMENTAL_CHART_TYPES";
export const VIEW_BASIC_SUMMARIES = "VIEW_BASIC_SUMMARIES";
export const VIEW_ADVANCED_SUMMARIES = "VIEW_ADVANCED_SUMMARIES";
export const VIEW_EXPERIMENTAL_SUMMARIES = "VIEW_EXPERIMENTAL_SUMMARIES";
export const MANAGE_USERS = "MANAGE_USERS";

const basicPermissions = [
  CHANGE_BASIC_SETTINGS,
  VIEW_BASIC_CHART,
  VIEW_BASIC_CHART_TYPES,
  VIEW_BASIC_TABS,
  VIEW_BASIC_SUMMARIES,
];

const advancedPermissions = [
  CHANGE_ADVANCED_SETTINGS,
  VIEW_ADVANCED_CHART,
  VIEW_ADVANCED_CHART_TYPES,
  VIEW_ADVANCED_TABS,
  VIEW_ADVANCED_SUMMARIES,
];

const adminPermissions = [
  MANAGE_USERS,
  VIEW_EXPERIMENTAL_CHART,
  VIEW_EXPERIMENTAL_CHART_TYPES,
  VIEW_EXPERIMENTAL_TABS,
  VIEW_EXPERIMENTAL_SUMMARIES,
];

const roles = {
  basic: basicPermissions,
  premium: [...basicPermissions, ...advancedPermissions],
  admin: [...basicPermissions, ...advancedPermissions, ...adminPermissions],
};

export const createPermissionChecker = (user) => (permission) => {
  const role = _.get(
    user,
    "signInUserSession.accessToken.payload.cognito:groups[0]",
    constants.BASIC
  );
  const permissions = roles[role];
  return _.includes(permissions, permission);
};
