import { nitrate } from "./customIcons";
import {
  faAtom,
  faBolt,
  faCalendarDays,
  faCookie,
  faCubesStacked,
  faDollarSign,
  faFaucet,
  faGauge,
  faHourglass,
  faMagnifyingGlassDollar,
  faPlug,
  faSeedling,
  faSmog,
  faWater,
  faCloudRain,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const icons = {
  "fa-solid fa-atom": faAtom,
  "fa-solid fa-bolt": faBolt,
  "fa-solid fa-calendar-days": faCalendarDays,
  "fa-solid fa-cookie": faCookie,
  "fa-solid fa-cubes-stacked": faCubesStacked,
  "fa-solid fa-dollar-sign": faDollarSign,
  "fa-solid fa-faucet": faFaucet,
  "fa-solid fa-gauge": faGauge,
  "fa-solid fa-hourglass": faHourglass,
  "fa-solid fa-magnifying-glass-dollar": faMagnifyingGlassDollar,
  "fa-solid fa-plug": faPlug,
  "fa-solid fa-seedling": faSeedling,
  "fa-solid fa-smog": faSmog,
  "fa-solid fa-water": faWater,
  "fa-solid fa-cloud-rain": faCloudRain,
  nitrate: nitrate,
};

export default icons;

export const getFaIcon = (icon) => {
  if (_.includes(_.keys(icons), icon)) {
    return icons[icon];
  }
  throw new Error(`Icon "${icon}" not found in icons.js`);
};
