import ChartContainer from "./ChartContainer";
import ChartWeatherLongTermAverages from "./ChartWeatherLongTermAverages";
import ChartWeatherTimeSeries from "./ChartWeatherTimeSeries";
import ChartsContainer from "./ChartsContainer";
import {
  COLOR_RAIN,
  COLOR_EVAP,
  COLOR_TMIN,
  COLOR_TMAX,
  COLOR_RAD,
} from "./colors";
import {
  millimetres,
  degreesCelsius,
  kilowattHoursPerSquareMetre,
} from "./numberFormatters";
import { WEATHER_STATION, HARVEST_YEARS } from "./settingIds";
import weatherStations from "./weatherStations";
import axios from "axios";
import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

const charts = [
  {
    id: "Temperature",
    series: [
      {
        id: "mint",
        name: "Min temperature",
        color: COLOR_TMIN,
      },
      {
        id: "maxt",
        name: "Max temperature",
        color: COLOR_TMAX,
      },
    ],
    unit: "°C",
    format: degreesCelsius,
    aggregator: _.mean,
  },
  {
    id: "Rain and Pan Evaporation",
    series: [
      {
        id: "rain",
        name: "Total rainfall",
        color: COLOR_RAIN,
      },
      {
        id: "evap",
        name: "Total pan evaporation",
        color: COLOR_EVAP,
      },
    ],
    unit: "mm",
    format: millimetres,
    aggregator: _.sum,
  },
  {
    id: "Solar Radiation",
    series: [
      {
        id: "radn",
        name: "Radiation",
        color: COLOR_RAD,
      },
    ],
    unit: "MJ/m²",
    format: kilowattHoursPerSquareMetre,
    aggregator: _.sum,
  },
];

const types = [
  {
    id: "annualTimeSeries",
    name: "Annual time-series",
    component: ChartWeatherTimeSeries,
    transform: (data, agregator) =>
      _.transform(
        _.groupBy(data, "year"),
        function (result, value, key) {
          result.push({
            x: _.toNumber(key),
            y: agregator(_.map(value, "y")),
          });
        },
        []
      ),
  },
  {
    id: "longTermAverages",
    name: "Long-term averages",
    component: ChartWeatherLongTermAverages,
    transform: (data) =>
      _.transform(
        _.groupBy(data, "month"),
        function (result, value, key) {
          result.push({
            x: DateTime.utc(2000, _.toNumber(key)).toJSDate(),
            y: _.mean(_.map(value, "y")),
          });
        },
        []
      ),
  },
];

const typesById = _.zipObject(_.map(types, "id"), types);

function Weather({ settings, data, showHeader }) {
  const [typeId, setTypeId] = useState(_.first(_.keys(typesById)));
  const type = typesById[typeId];
  return (
    <ChartsContainer
      header={
        showHeader && (
          <div>
            {types.map((item) => (
              <div key={item.id} className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name={`weather-type-radios`}
                  id={`weather-type-radios-${item.id}`}
                  checked={typeId === item.id}
                  onChange={() => setTypeId(item.id)}
                />
                <label
                  className="form-check-label align-middle"
                  htmlFor={`weather-type-radios-${item.id}`}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>
        )
      }
    >
      {charts.map((chart) => {
        return (
          <ChartContainer
            key={chart.id}
            title={chart.id}
            subtitle={[
              `${
                _.values(weatherStations).find(
                  ({ value }) => value === settings[WEATHER_STATION]
                ).label
              } (${settings[HARVEST_YEARS].start}-${
                settings[HARVEST_YEARS].end
              })`,
            ]}
          >
            <type.component
              id={chart.name}
              series={chart.series.map((seriesItem) => ({
                ...seriesItem,
                data: type.transform(
                  _.map(data, (item) => ({
                    year: item.Year,
                    month: item.Mon,
                    y: item[seriesItem.id],
                  })),
                  chart.aggregator
                ),
              }))}
              format={chart.format}
              unit={chart.unit}
              xLabel={type.xLabel}
              timeFormat={type.timeFormat}
            />
          </ChartContainer>
        );
      })}
    </ChartsContainer>
  );
}

Weather.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      Year: PropTypes.number.isRequired,
      Mon: PropTypes.number.isRequired,
      mint: PropTypes.number.isRequired,
      maxt: PropTypes.number.isRequired,
      radn: PropTypes.number.isRequired,
      rain: PropTypes.number.isRequired,
      evap: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  settings: PropTypes.shape({
    [WEATHER_STATION]: PropTypes.string.isRequired,
    [HARVEST_YEARS]: PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  showHeader: PropTypes.bool,
};

Weather.defaultProps = {
  showHeader: true,
};

export default function WithWeatherData({ settings, showHeader }) {
  const [data, setData] = useState([]);
  const stationId = settings[WEATHER_STATION];
  useEffect(() => {
    setData([]);
    const fetch = async () => {
      const response = await axios.get(
        `/weather-stations/${stationId}_MTS.json`
      );
      setData(response.data);
    };
    fetch();
  }, [stationId]);
  const filtered = _.filter(
    data,
    ({ Year }) =>
      Year >= settings[HARVEST_YEARS].start &&
      Year <= settings[HARVEST_YEARS].end
  );
  return (
    <Weather data={filtered} settings={settings} showHeader={showHeader} />
  );
}
