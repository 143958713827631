import fields from "./fields";
import * as settingIds from "./settingIds";
import axios from "axios";
import _ from "lodash";

export const addDerivedPropertiesToScenarioData = (data, settings) =>
  _.mapValues(data, (year) => {
    let yearDataWithDerived = { ...year };
    const derivableFields = _.values(fields).filter((field) =>
      _.isFunction(field.generate)
    );
    derivableFields.forEach((field) => {
      yearDataWithDerived[field.id] = field.generate(
        yearDataWithDerived,
        settings
      );
    });
    return yearDataWithDerived;
  });

export const combineScenariosData = (scenarios) => {
  const baseScenario = _.first(_.values(scenarios));
  const years = _.intersection(..._.values(scenarios).map(_.keys));
  const scenarioFieldIds = _.keys(_.first(_.values(baseScenario)));
  return _.zipObject(
    years,
    years.map((year) =>
      _.zipObject(
        scenarioFieldIds,
        scenarioFieldIds.map((field) =>
          _.zipObject(
            _.keys(scenarios),
            _.keys(scenarios).map(
              (scenario) => scenarios[scenario][year][field]
            )
          )
        )
      )
    )
  );
};

const fetchCache = {};

const fetchFromMiddleware = async (fetchSettings) => {
  const key = JSON.stringify(fetchSettings);
  if (!(key in fetchCache)) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}scenario`,
      fetchSettings
    );
    fetchCache[key] = data;
  }
  return fetchCache[key];
};

const fetchSettingIds = [
  settingIds.DRY_OFF_DAYS,
  settingIds.FERTILISER_AMOUNT,
  settingIds.FERTILISER_DISTRIBUTION,
  settingIds.FALLOW,
  settingIds.HARVEST_YEARS,
  settingIds.PLANTING,
  settingIds.RATOON,
  settingIds.REGION,
  settingIds.WEATHER_STATION,
  settingIds.SOIL,
  settingIds.TILLAGE,
  settingIds.IRRIGATION_FREQUENCY,
  settingIds.IRRIGATION_AMOUNT,
  settingIds.IRRIGATION_SYSTEM,
  settingIds.IRRIGATION_ALLOCATION,
];

export const extractFetchSettings = (settings) =>
  _.omitBy(
    _.zipObject(
      fetchSettingIds,
      fetchSettingIds.map((id) => settings[id])
    ),
    _.isUndefined
  );

const getScenarioData = async (paddock, scenarioId, fetcher) => {
  const settings = paddock.scenarios[scenarioId].settings;
  const fetchSettings = extractFetchSettings(settings);
  const fetched = await fetcher(fetchSettings);
  return _.mapValues(fetched, (value) =>
    addDerivedPropertiesToScenarioData(value, settings)
  );
};

export const getPaddockData = async (
  paddock,
  fetcher = fetchFromMiddleware
) => {
  const scenariosData = {};
  for (let scenarioId of _.keys(paddock.scenarios)) {
    scenariosData[scenarioId] = await getScenarioData(
      paddock,
      scenarioId,
      fetcher
    );
  }
  const data = {
    years: combineScenariosData(
      _.mapValues(scenariosData, _.property("years"))
    ),
    stats: combineScenariosData(
      _.mapValues(scenariosData, _.property("stats"))
    ),
  };
  return data;
};

const clientFields = _.values(fields).filter(
  (item) => item.isRequiredByClient || _.isFunction(item.generate)
);

export const scaleData = (data, scale) =>
  _.mapValues(data, (year) =>
    _.zipObject(
      clientFields.map((field) => field.id),
      clientFields.map((field) =>
        _.mapValues(year[field.id], (scenarioValue) => {
          if (field.isScalable) {
            return scenarioValue * scale;
          }
          return scenarioValue;
        })
      )
    )
  );
