import { useAuth } from "./AuthGate";
import Loading from "./Loading";
import { getPaddocksState, setAuthToken } from "./user";
import _ from "lodash";
import { useEffect, useState } from "react";

const withPaddocks = (WrappedComponent) => {
  const WithPaddocks = (props) => {
    const { user } = useAuth();
    setAuthToken(user.signInUserSession.idToken.jwtToken);
    const [paddocks, setPaddocks] = useState();
    const [error, setError] = useState(false);
    useEffect(() => {
      const fetch = async () => {
        try {
          const paddocksState = await getPaddocksState();
          setPaddocks(paddocksState);
        } catch (e) {
          console.error(e);
          setError(e);
        }
      };
      fetch();
    }, []);
    if (_.isUndefined(paddocks)) {
      return <Loading error={error} />;
    }
    return <WrappedComponent {...props} paddocks={paddocks} />;
  };
  return WithPaddocks;
};

export default withPaddocks;
