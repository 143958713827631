import AdminUserAddForm from "./AdminUserAddForm";
import * as constants from "./constants";
import interactions from "./interactions";
import withAdminUsers from "./withAdminUsers";
import {
  faExternalLink,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import PropTypes from "proptypes";

export function AdminUsers({ currentUsername, users, update, add, isAdding }) {
  return (
    <div>
      <h4>Add user</h4>
      <AdminUserAddForm
        isAdding={isAdding}
        onAdd={(user) => {
          interactions.publish({
            category: "Admin users",
            action: "added",
            label: user.role,
          });
          add(user);
        }}
        existingUserNames={users.map((user) => user.username)}
      />
      <hr className="my-5" />
      <h4>Users</h4>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Username</th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
              <th scope="col" className="text-end">
                Role
              </th>
            </tr>
          </thead>
          <tbody>
            {_.reverse(_.sortBy(users, "createdAt")).map((user) => (
              <tr key={user.username}>
                <th scope="row">{user.username}</th>
                <td>{user.email}</td>
                <td>{_.lowerCase(user.status)}</td>
                <td className="text-end">
                  <select
                    className="form-select w-auto d-inline-block"
                    value={user.role}
                    onChange={(e) => {
                      interactions.publish({
                        category: "Admin users",
                        action: "updated",
                        label: e.target.value,
                      });
                      update(user.username, { role: e.target.value });
                    }}
                    disabled={currentUsername === user.username}
                  >
                    <option value={constants.BASIC}>{constants.BASIC}</option>
                    <option value={constants.PREMIUM}>
                      {constants.PREMIUM}
                    </option>
                    <option value={constants.ADMIN}>{constants.ADMIN}</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {users.length === 0 && (
        <div className="text-center mt-3 text-secondary">
          <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
        </div>
      )}
      <p className="text-end small mt-3 mb-0">
        <a
          href={`https://ap-southeast-2.console.aws.amazon.com/cognito/v2/idp/user-pools/${process.env.REACT_APP_USER_POOL_ID}/users?region=ap-southeast-2`}
          target="_blank"
          rel="noreferrer"
        >
          open in AWS cognito console
          <FontAwesomeIcon icon={faExternalLink} className="ms-2" />
        </a>
      </p>
    </div>
  );
}

AdminUsers.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      email: PropTypes.string,
      status: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
  update: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  currentUsername: PropTypes.string.isRequired,
};

export default withAdminUsers(AdminUsers);
