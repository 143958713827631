import * as constants from "./constants";
import classNames from "classnames";
import PropTypes from "proptypes";
import { useState, useEffect } from "react";

const initialUserState = {
  username: "",
  email: "",
  password: "",
  role: constants.BASIC,
};

export default function AdminUserAddForm({
  isAdding,
  onAdd,
  existingUserNames,
}) {
  const [user, setUser] = useState(initialUserState);
  const [hasValidated, setHasValidated] = useState(false);
  useEffect(() => {
    setHasValidated(false);
    setUser(initialUserState);
  }, [isAdding]);
  return (
    <form
      className={classNames("row gy-2 gx-3 align-items-center", {
        "was-validated": hasValidated,
      })}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        setHasValidated(true);
        // @TODO: Have nicer validation for this.
        const isUsernameTaken = existingUserNames.some(
          (item) => item === user.username
        );
        if (isUsernameTaken) {
          alert("Username is already being used.");
        } else if (e.target.checkValidity()) {
          onAdd(user);
        }
      }}
    >
      <div className="col-12 col-md-auto">
        <label className="visually-hidden" htmlFor="add-user-username">
          Username
        </label>
        <input
          type="text"
          className="form-control"
          id="add-user-username"
          placeholder="Username"
          disabled={isAdding}
          value={user.username}
          onChange={(e) => setUser({ ...user, username: e.target.value })}
          required
        />
        <div className="invalid-feedback">Provide a unique username.</div>
      </div>
      <div className="col-12 col-md-auto">
        <label className="visually-hidden" htmlFor="add-user-email">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="add-user-email"
          placeholder="Email"
          disabled={isAdding}
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          required
        />
        <div className="invalid-feedback">Enter in a valid email.</div>
      </div>
      <div className="col-12 col-md-auto">
        <label className="visually-hidden" htmlFor="add-user-password">
          Temporary password
        </label>
        <input
          type="text"
          className="form-control"
          id="add-user-password"
          placeholder="Temporary password"
          disabled={isAdding}
          value={user.password}
          minLength={6}
          required
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
        <div className="invalid-feedback">
          Password must be at least 6 characters.
        </div>
      </div>
      <div className="col-12 col-md-auto">
        <label className="visually-hidden" htmlFor="autoSizingSelect">
          Role
        </label>
        <select
          disabled={isAdding}
          className="form-select"
          id="autoSizingSelect"
          value={user.role}
          onChange={(e) => setUser({ ...user, role: e.target.value })}
        >
          <option value={constants.BASIC}>{constants.BASIC}</option>
          <option value={constants.PREMIUM}>{constants.PREMIUM}</option>
          <option value={constants.ADMIN}>{constants.ADMIN}</option>
        </select>
      </div>
      <div className="col-12 col-md-auto">
        <button
          disabled={isAdding}
          type="submit"
          className="btn btn-outline-primary"
        >
          {isAdding ? "Adding..." : "Add"}
        </button>
      </div>
      <p className="text-secondary small mb-0">
        An email will automatically be sent to the new user containing the
        temporary password.
      </p>
    </form>
  );
}

AdminUserAddForm.propTypes = {
  isAdding: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  existingUserNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};
