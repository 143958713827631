import Modal from "./Modal";
import PropTypes from "proptypes";
import { useState } from "react";

export default function ContactUs({ children }) {
  const [show, setShow] = useState(false);
  return (
    <>
      {children(() => setShow(true))}
      <Modal size="md" scrollable show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Contact us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            To get in contact, please send an email to the following address:
          </p>
          <a
            href="mailto:info@i-rat.net?Subject=From%20iRAT%20App"
            target="_blank"
            rel="noreferrer"
          >
            info@i-rat.net
          </a>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-dark"
            onClick={() => setShow(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ContactUs.propTypes = {
  children: PropTypes.func.isRequired,
};
