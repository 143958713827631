import Modal from "./Modal";
import PaddockSettings from "./PaddockSettings";
import { paddockSettings } from "./settings";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import PropTypes from "proptypes";
import { useState, useEffect } from "react";

export default function PaddockSettingsButton({
  checkPermission,
  paddock,
  onChange,
}) {
  const [draft, setDraft] = useState(paddock.scenarios);
  const [show, setShow] = useState(false);
  useEffect(() => {
    setDraft(paddock.scenarios);
  }, [paddock.scenarios]);
  const hasChanges = !_.isEqual(draft, paddock.scenarios);
  return (
    <>
      <div className="d-grid irat-tour-paddock-settings-button">
        <button
          className="btn btn-outline-dark d-flex align-items-center justify-content-between"
          onClick={() => setShow(true)}
        >
          <span>Paddock settings</span>
          <FontAwesomeIcon icon={faSliders} />
        </button>
      </div>
      <Modal
        scrollable
        size="xl"
        centered
        fullscreen="xl-down"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="paddock-settings-modal"
        onExit={() => setDraft(paddock.scenarios)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="paddock-settings-modal">
            Settings: {paddock.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaddockSettings
            settings={paddockSettings}
            values={draft}
            onChange={(changes) => setDraft({ ...draft, ...changes })}
            checkPermission={checkPermission}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-dark"
            onClick={() => {
              setShow(false);
              if (hasChanges) {
                onChange(draft);
              }
            }}
          >
            {hasChanges ? "Apply" : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

PaddockSettingsButton.propTypes = {
  paddock: PropTypes.shape({
    scenarios: PropTypes.shape({}).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  checkPermission: PropTypes.func.isRequired,
};
