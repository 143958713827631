import { useAlerts } from "./Alerts";
import { useAuth } from "./AuthGate";
import Charts, { getChartTabIds } from "./Charts";
import Summary from "./Summary";
import Weather from "./Weather";
import { getPaddockData } from "./api";
import { VIEW_ADVANCED_TABS } from "./auth";
import { updatePersistentPaddockState } from "./user";
import * as jsonpatch from "fast-json-patch";
import produce from "immer";
import _ from "lodash";
import { useEffect, useState, useCallback, useMemo, useRef } from "react";

const fetchFailedAlert = {
  id: "scenario-data-fetch-failed",
  type: "danger",
  message: "Failed to get scenario data. Try refreshing the page.",
};

export const usePaddockData = ({ selectedPaddock }) => {
  const { addAlert, removeAlert } = useAlerts();
  const [data, setData] = useState({});
  useEffect(() => {
    let isCancelled = false;
    const getData = async () => {
      setData((state) => _.omit(state, selectedPaddock.id));
      try {
        const paddockData = await getPaddockData(selectedPaddock);
        removeAlert(fetchFailedAlert);
        if (!isCancelled) {
          setData((state) =>
            produce(state, (draft) => {
              draft[selectedPaddock.id] = paddockData;
            })
          );
        }
      } catch (e) {
        console.error(e);
        addAlert(fetchFailedAlert);
      }
    };
    getData();
    return () => {
      isCancelled = true;
    };
  }, [selectedPaddock, addAlert, removeAlert]);
  const isLoading = _.isUndefined(data[selectedPaddock.id]);
  return { isLoading, data };
};

export const useAfterSignout = (cb) => {
  const [isSigningOut, setIsSigningOut] = useState(false);
  useEffect(
    () => () => {
      if (isSigningOut) {
        cb();
      }
    },
    [isSigningOut, cb]
  );
  return () => setIsSigningOut(true);
};

const saveFailedAlert = {
  id: "save-failed",
  type: "danger",
  message:
    "Failed to save! Changes made from now may not be saved to your account.",
};

export const usePaddocksState = (initialPaddocksState) => {
  const { addAlert, removeAlert } = useAlerts();
  const [isSaving, setIsSaving] = useState(false);
  const [paddocks, setPaddocksState] = useState(initialPaddocksState);
  const paddocksRef = useRef(initialPaddocksState);
  const setPaddocks = useCallback(
    (updatedPaddocks) => {
      setIsSaving(true);
      setPaddocksState(updatedPaddocks);
      const patch = jsonpatch.compare(paddocksRef.current, updatedPaddocks);
      paddocksRef.current = updatedPaddocks;
      updatePersistentPaddockState(patch, {
        onError: (error) => {
          console.error(error);
          addAlert(saveFailedAlert);
        },
        onSuccess: () => removeAlert(saveFailedAlert),
        onSaved: () => setIsSaving(false),
      });
    },
    [addAlert, removeAlert]
  );

  const [selectedPaddockId, setSelectedPaddockId] = useState(
    _.first(_.keys(paddocks))
  );
  const selectedPaddock =
    paddocks[selectedPaddockId] || _.first(_.values(paddocks));
  return {
    isSaving,
    paddocks,
    setPaddocks,
    selectedPaddock,
    setSelectedPaddockId,
  };
};

export const usePaddockTabs = ({ selectedPaddockId }) => {
  const { checkPermission } = useAuth();
  const paddockTabComponents = { Summary };
  const chartTabIds = useMemo(
    () => getChartTabIds(checkPermission),
    [checkPermission]
  );
  chartTabIds.forEach((id) => {
    paddockTabComponents[id] = Charts;
  });
  if (checkPermission(VIEW_ADVANCED_TABS)) {
    paddockTabComponents.Weather = Weather;
  }
  const [selectedTab, setSelectedTab] = useState({});
  const selectedTabId = selectedTab[selectedPaddockId] || "Summary";
  return {
    paddockTabIds: _.keys(paddockTabComponents),
    chartTabIds,
    PaddockTabComponent: paddockTabComponents[selectedTabId],
    selectedTabId,
    setSelectedTab: (id) => {
      setSelectedTab(
        produce(selectedTab, (draft) => {
          draft[selectedPaddockId] = id;
        })
      );
    },
  };
};
