import "./ChartsContainer.scss";
import PropTypes from "prop-types";

export default function ChartsContainer({ header, children }) {
  return (
    <section>
      {header}
      {!!header && <hr />}
      <div className="mb-n5">
        <div className="row irat-charts-container">{children}</div>
      </div>
    </section>
  );
}

ChartsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]).isRequired,
};
