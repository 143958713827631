import PropTypes from "proptypes";

export default function PaddockNav({ tabs, selected, onSelect }) {
  const switchSize = tabs.length > 6 ? "xl" : "lg";
  return (
    <div className="irat-tour-paddock-nav">
      <ul
        className={`nav nav-pills flex-column flex-md-row d-none d-${switchSize}-flex`}
      >
        {tabs.map((item) => (
          <li key={item} className="nav-item">
            <button
              className={`nav-link ${item === selected ? "active" : ""}`}
              aria-current={item === selected ? "page" : null}
              onClick={() => onSelect(item)}
            >
              {item}
            </button>
          </li>
        ))}
      </ul>
      <div className={`form-floating d-${switchSize}-none text-dark`}>
        <select
          id="paddock-nav-select"
          className="form-select"
          aria-label="Paddock nav"
          onChange={(e) => onSelect(e.target.value)}
          value={selected}
        >
          {tabs.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
        <label htmlFor="paddock-nav-select">Outputs</label>
      </div>
    </div>
  );
}

PaddockNav.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  selected: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};
