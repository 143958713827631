import AddPaddock from "./AddPaddock";
import ConfirmerModal from "./ConfirmerModal";
import PaddockName from "./PaddockName";
import "./PaddocksNav.scss";
import { generateUniquePaddockName } from "./utils";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "proptypes";
import { useState } from "react";

export default function PaddocksNav({
  onAdd,
  onSelect,
  paddocks,
  onRemove,
  selected,
  onChange,
}) {
  const [isRenaming, setIsRenaming] = useState(false);
  return (
    <div className="d-flex irat-paddocks-nav">
      <ul className="nav nav-tabs flex-shrink-0 flex-grow-1">
        {paddocks.map((paddock) => (
          <li key={paddock.id} className="nav-item">
            <button
              className={classNames("nav-link p-0", {
                active: paddock.id === selected,
              })}
              aria-current="page"
            >
              <span
                className="px-3 py-2 d-inline-block irat-tour-paddock-nav__name"
                role="button"
                onClick={() => {
                  if (paddock.id === selected) {
                    setIsRenaming(true);
                  } else {
                    onSelect(paddock.id);
                  }
                }}
              >
                {generateUniquePaddockName(paddocks, paddock)}
              </span>
              <ConfirmerModal
                title="Delete paddock"
                body="Are you sure you want to delete the paddock?"
                onConfirm={() => onRemove(paddock.id)}
              >
                <FontAwesomeIcon className="me-3" icon={faTimes} />
              </ConfirmerModal>
            </button>
          </li>
        ))}
        <li className="nav-item">
          <AddPaddock
            onAdd={onAdd}
            order={_.max(_.values(paddocks).map(({ order }) => order)) + 1}
          >
            {(show) => (
              <button
                className="nav-link irat-tour-paddock-nav__add"
                aria-label="Add new paddock"
                onClick={show}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            )}
          </AddPaddock>
        </li>
      </ul>
      <PaddockName
        value={paddocks.find((paddock) => paddock.id === selected).name}
        onChange={onChange}
        isShowing={isRenaming}
        onClose={() => setIsRenaming(false)}
      />
    </div>
  );
}

PaddocksNav.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  paddocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};
