import _ from "lodash";
import PropTypes from "proptypes";
import { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";

export default function PaddockName({ value, onChange, onClose, isShowing }) {
  const [draft, setDraft] = useState(value);
  useEffect(() => {
    setDraft(value);
  }, [value]);
  const inputRef = useRef();
  return (
    <Modal
      size="sm"
      scrollable
      centered
      show={isShowing}
      aria-labelledby="paddock-name-modal"
      onEnter={() => inputRef.current.select()}
      onHide={() => {
        if (isShowing) {
          onClose();
        }
      }}
      onExited={() => {
        setDraft(value);
        if (isShowing) {
          onClose();
        }
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="paddock-name-modal">Paddock name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          ref={inputRef}
          type="text"
          className="form-control"
          placeholder="Paddock name"
          value={draft}
          onChange={(e) => setDraft(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onChange(draft);
              onClose();
            }
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-dark"
          onClick={() => {
            onChange(draft);
            onClose();
          }}
          disabled={_.trim(draft).length === 0}
        >
          Save
        </button>
        <button className="btn btn-outline-dark" onClick={onClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

PaddockName.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isShowing: PropTypes.bool,
};

PaddockName.defaultProps = {
  isShowing: false,
};
