import { defaultSettings } from "./createPaddock.js";
import {
  lowFrequency,
  mediumFrequency,
  highFrequency,
  swBased30drip,
  swBased60,
  swBased40,
  swBased30,
  swBased30late,
  swBased50,
  swBased50late,
} from "./irrigationFrequencies.js";
import * as irrigationSystemIds from "./irrigationSystemIds.js";
import * as settingIds from "./settingIds.js";
import weatherStations from "./weatherStations.js";
import _ from "lodash";

const burdekinFertiliserSetting = {
  isVisible: true,
  default: (settings) =>
    settings[settingIds.IRRIGATION_SYSTEM] === irrigationSystemIds.FURROW
      ? "Surf1"
      : "SubSurfDrip1",
};

const mackayFertiliserSetting = {
  label: "N distribution",
  options: () => [
    {
      label: "Plant: 20% at planting, 80% on day 90 | Ratoons: 100% on day 30",
      value: "MW_Surf2",
    },
  ],
  default: () => "MW_Surf2",
};

const regions = {
  Burdekin: {
    isEnabled: true,
    id: "Burdekin",
    label: "Burdekin",
    root: "V6",
    weatherStations: [weatherStations.AyrDPI, weatherStations.Clare],
    geojson: {
      type: "FeatureCollection",
      name: "burdekin",
      crs: {
        type: "name",
        properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
      },
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [146.93280298439163, -19.37031782779451],
                [146.93280298439163, -20.303327654659256],
                [147.7408714315357, -20.303327654659256],
                [147.7408714315357, -19.37031782779451],
                [146.93280298439163, -19.37031782779451],
              ],
            ],
          },
        },
      ],
    },
    settings: {
      [settingIds.SOIL]: {
        options: () => [
          {
            value: "IrrWeb15",
            label: "Sodic from 60cm depth, low PAW, low infiltration",
          },
          {
            value: "Kala",
            label: "Loam over sand, low PAW, high infiltration",
          },
          {
            value: "Mulg",
            label: "Uniform clay, medium PAW, low infiltration",
          },
          {
            value: "IrrWeb7",
            label: "Uniform loam, medium PAW, medium infiltration",
          },
          {
            value: "IrrWeb8",
            label: "Uniform clay, high PAW, low infiltration",
          },
          {
            value: "AirdH",
            label: "Uniform clay-loam, high PAW, medium infiltration",
          },
        ],
        default: () => "Mulg",
      },
      [settingIds.TILLAGE]: {
        options: (settings) => {
          const options = [
            {
              value: settings[settingIds.IS_BURNT] ? ["T2"] : ["T1"],
              label: "Zonal tillage",
            },
          ];
          if (
            settings[settingIds.IRRIGATION_SYSTEM] !==
            irrigationSystemIds.SUBSURFACE_DRIP
          ) {
            options.push(
              ...[
                {
                  value: ["T3"],
                  label: "Minimum tillage",
                },
                {
                  value: ["T4"],
                  label: "Moderate tillage",
                },
                {
                  value: ["T5"],
                  label: "Intensive tillage",
                },
              ]
            );
          }
          return options;
        },
        default: (settings) => {
          if (
            settings[settingIds.IRRIGATION_SYSTEM] ===
            irrigationSystemIds.SUBSURFACE_DRIP
          ) {
            return ["T2"];
          }
          return ["T4"];
        },
      },
      [settingIds.PLANTING]: {
        options: () => [
          {
            value: "15MarV1R4",
            label: "March planting (15 mo) | 4 ratoons (13 mo)",
          },
          {
            value: "15AprV1R4",
            label: "April planting (15 mo) | 4 ratoons (13 mo)",
          },
          {
            value: "15MayV1R4",
            label: "May planting (15 mo) | 4 ratoons (13 mo)",
          },
          {
            value: "15MayV2R4",
            label:
              "May planting (15 mo) | 3 ratoons (13 mo) + 1 early-cut ratoon (8 mo)",
          },
          {
            value: "15JunV1R4",
            label: "June planting (13 mo) | 4 ratoons (13 mo)",
          },
          {
            value: "15AugV1R4",
            label: "August planting (12 mo) | 4 ratoons (13 mo)",
          },
        ],
        default: () => "15AprV1R4",
      },
      [settingIds.IRRIGATION_FREQUENCY]: {
        options: (settings) => {
          const options = [
            highFrequency,
            mediumFrequency,
            lowFrequency,
            swBased30drip,
            swBased40,
            swBased60,
          ];
          const irrigationSystem = settings[settingIds.IRRIGATION_SYSTEM];
          return options.filter(({ irrigationSystems }) =>
            _.includes(irrigationSystems, irrigationSystem)
          );
        },
        default: (settings) => {
          if (
            settings[settingIds.IRRIGATION_SYSTEM] ===
            irrigationSystemIds.SUBSURFACE_DRIP
          ) {
            return swBased30drip.value;
          }
          if (
            settings[settingIds.IRRIGATION_SYSTEM] ===
            irrigationSystemIds.FURROW
          ) {
            return mediumFrequency.value;
          }
          return mediumFrequency.value;
        },
      },
      [settingIds.IRRIGATION_ALLOCATION]: {
        options: () => [
          { label: "dummy required for having slider at end", value: -1 },
          { label: "Unlimited", value: 999 },
        ],
        default: () => 999,
        isDisabled: true,
      },
      [settingIds.IRRIGATION_SYSTEM]: {
        options: (settings) => {
          const options = [
            { value: irrigationSystemIds.FURROW, label: "Furrow" },
          ];
          if (_.includes(["T1", "T2"], _.first(settings[settingIds.TILLAGE]))) {
            options.push({
              value: irrigationSystemIds.SUBSURFACE_DRIP,
              label: "Subsurface drip",
            });
          }
          return options;
        },
        default: () => irrigationSystemIds.FURROW,
      },
      [settingIds.IRRIGATION_AMOUNT]: {
        options: (settings) => {
          const irrigationSystemId = settings[settingIds.IRRIGATION_SYSTEM];
          const irrigationAmountOptions = {
            [irrigationSystemIds.FURROW]: _.map(
              [..._.range(50, 215, 15), 250, 350],
              (value) => ({
                value,
                label: value,
              })
            ),
            [irrigationSystemIds.SUBSURFACE_DRIP]: _.map([10, 15], (value) => ({
              value,
              label: value,
            })),
          };
          return (
            irrigationAmountOptions[irrigationSystemId] ||
            irrigationAmountOptions[irrigationSystemIds.FURROW]
          );
        },
        default: (settings) => {
          const irrigationSystemId = settings[settingIds.IRRIGATION_SYSTEM];
          const irrigationAmountDefaults = {
            [irrigationSystemIds.FURROW]: 110,
            [irrigationSystemIds.SUBSURFACE_DRIP]: 10,
          };
          return (
            irrigationAmountDefaults[irrigationSystemId] ||
            irrigationAmountDefaults[irrigationSystemIds.FURROW]
          );
        },
      },
      [settingIds.FERTILISER_AMOUNT]: {
        options: () => [
          { label: "Plant: 180 kg/ha | Ratoons: 180 kg/ha", value: 180 },
          { label: "Plant: 200 kg/ha | Ratoons: 200 kg/ha", value: 200 },
          { label: "Plant: 220 kg/ha | Ratoons: 220 kg/ha", value: 220 },
        ],
        default: () => 200,
      },
      [settingIds.DRIP_ENERGY_USED]: {
        isVisible: true,
      },
      [settingIds.FERTILISER_DISTRIBUTION]: {
        ...burdekinFertiliserSetting,
        isDisabled: (scenarios) =>
          !_.find(_.map(scenarios, "settings"), {
            [settingIds.IRRIGATION_SYSTEM]: irrigationSystemIds.FURROW,
          }),
        options: () => [
          {
            label:
              "Plant: 25% at planting, 75% on day 90 | Ratoons: 100% on day 2",
            value: "Surf1",
          },
        ],
        label: "N distribution (furrow)",
      },
      [settingIds.SECONDARY_FERTILISER_DISTRIBUTION]: {
        ...burdekinFertiliserSetting,
        isDisabled: (scenarios) =>
          !_.find(_.map(scenarios, "settings"), {
            [settingIds.IRRIGATION_SYSTEM]: irrigationSystemIds.SUBSURFACE_DRIP,
          }),
        options: () => [
          {
            label:
              "Plant: 7 doses, 30-day apart, starting on day 2 | Ratoons: 6 doses, 30-day apart, starting on day 2",
            value: "SubSurfDrip1",
          },
        ],
        label: "N distribution (subsurface drip)",
      },
      [settingIds.IS_BURNT]: {
        isDisabled: false,
        isCheckedWhenDisabled: true,
      },
      [settingIds.DRY_OFF_DAYS]: {
        options: () => [
          { label: "35 days", value: 35 },
          { label: "56 days", value: 56 },
          { label: "77 days", value: 77 },
        ],
        default: () => 35,
      },
      [settingIds.RATOON]: {
        options: () => [
          { value: "ALL", label: "Averaged across all crops" },
          { value: 0, label: "For plant only" },
          { value: 1, label: "For R1 only" },
          { value: 2, label: "For R2 only" },
          { value: 3, label: "For R3 only" },
          { value: 4, label: "For R4 only" },
        ],
        default: () => "All",
      },
      [settingIds.HARVEST_YEARS]: {
        min: 1971,
        max: 2020,
      },
    },
    scenarioDefaults: {
      current: {
        ..._.omit(defaultSettings, [
          settingIds.REGION,
          settingIds.WEATHER_STATION,
        ]),
        [settingIds.DRY_OFF_DAYS]: 35,
        [settingIds.FERTILISER_AMOUNT]: 200,
        [settingIds.FERTILISER_DISTRIBUTION]: "Furrow",
        [settingIds.IRRIGATION_ALLOCATION]: 999,
        [settingIds.IRRIGATION_AMOUNT]: 140,
        [settingIds.IRRIGATION_FREQUENCY]: lowFrequency.value,
        [settingIds.IRRIGATION_SYSTEM]: irrigationSystemIds.FURROW,
        [settingIds.PLANTING]: "15AprV1R4",
        [settingIds.SOIL]: "Mulg",
        [settingIds.TILLAGE]: ["T1"],
      },
      new: {
        ..._.omit(defaultSettings, [
          settingIds.REGION,
          settingIds.WEATHER_STATION,
        ]),
        [settingIds.DRY_OFF_DAYS]: 35,
        [settingIds.FERTILISER_AMOUNT]: 200,
        [settingIds.FERTILISER_DISTRIBUTION]: "Furrow",
        [settingIds.IRRIGATION_ALLOCATION]: 999,
        [settingIds.IRRIGATION_AMOUNT]: 110,
        [settingIds.IRRIGATION_FREQUENCY]: lowFrequency.value,
        [settingIds.IRRIGATION_SYSTEM]: irrigationSystemIds.FURROW,
        [settingIds.PLANTING]: "15AprV1R4",
        [settingIds.SOIL]: "Mulg",
        [settingIds.TILLAGE]: ["T1"],
      },
    },
  },
  Mackay: {
    isEnabled: process.env.REACT_APP_IS_MACKAY_ENABLED !== "false",
    id: "Mackay",
    label: "Mackay - Whitsunday",
    root: "V8",
    weatherStations: [
      weatherStations.Mackay,
      weatherStations.Proserpine,
      weatherStations.PlaneCreek,
      weatherStations.Eton,
    ],
    geojson: {
      type: "FeatureCollection",
      name: "mackay_whitsunday",
      crs: {
        type: "name",
        properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
      },
      features: [
        {
          type: "Feature",
          geometry: {
            coordinates: [
              [
                [148.43153754012422, -20.350887667309877],
                [148.43153754012422, -21.569083646998394],
                [149.41541369931485, -21.569083646998394],
                [149.41541369931485, -20.350887667309877],
                [148.43153754012422, -20.350887667309877],
              ],
            ],
            type: "Polygon",
          },
        },
      ],
    },
    settings: {
      [settingIds.SOIL]: {
        options: () => [
          {
            value: "Pind",
            label: "Strongly sodic duplex, loamy over clay, low PAW, low infiltration",
          },
          {
            value: "Came",
            label: "Uniform or gradational loamy soil, low PAW, medium infiltration",
          },
          {
            value: "Dunw",
            label: "Non-sodic duplex, sandy over clay, low PAW, high infiltration",
          },
          {
            value: "Sand",
            label: "Slightly sodic duplex, loamy over clay, low PAW, low infiltration",
          },
          {
            value: "Wago",
            label: "Non-cracking clay, medium PAW, very low infiltration",
          },
          {
            value: "Vicp",
            label: "Cracking clay, medium PAW, very low infiltration",
          },
        ],
        default: () => "Pind",
      },
      [settingIds.TILLAGE]: {
        options: () => [
          {
            value: ["MW_T1"],
            label: "Zonal tillage",
          },
          {
            value: ["MW_T2"],
            label: "Minimum tillage",
          },
          {
            value: ["MW_T3"],
            label: "Moderate tillage",
          },
          {
            value: ["MW_T4"],
            label: "Intensive tillage",
          },
        ],
        default: () => ["MW_T3"],
      },
      [settingIds.PLANTING]: {
        options: () => [
          {
            value: "MW_01MayR6V1",
            label: "May planting (16 mo) | 6 × early-cut ratoons (11-12 mo)",
          },
          {
            value: "MW_01MayR6V2",
            label: "May planting (16 mo) | 6 × late-cut ratoons (12-13 mo)",
          },
          {
            value: "MW_01AugR6V1",
            label: "August planting (14 mo) | 6 × early-cut ratoons (11-12 mo)",
          },
          {
            value: "MW_01AugR6V2",
            label: "August planting (14 mo) | 6 × late-cut ratoons (11-13 mo)",
          },
        ],
        default: () => "MW_01MayR6V1",
      },
      [settingIds.IRRIGATION_FREQUENCY]: {
        default: (settings) => {
          if (settings[settingIds.IRRIGATION_ALLOCATION] === 999) {
            return swBased50.value;
          } else {
            return swBased50late.value;
          }
        },
        options: (settings) => {
          if (settings[settingIds.IRRIGATION_ALLOCATION] === 999) {
            return [swBased30, swBased30late, swBased50, swBased50late];
          } else {
            return [swBased30, swBased30late, swBased50, swBased50late];
          }
        },
      },
      [settingIds.IRRIGATION_ALLOCATION]: {
        options: () => [
          { label: "1", value: 1 },
          { label: "2", value: 2 },
          { label: "3", value: 3 },
          { label: "4", value: 4 },
          { label: "Unlimited", value: 999 },
        ],
        default: () => 3,
        isDisabled: false,
      },
      [settingIds.IRRIGATION_SYSTEM]: {
        options: () => [
          { value: irrigationSystemIds.RAINFED, label: "Rainfed" },
          { value: irrigationSystemIds.FURROW, label: "Furrow" },
          {
            value: irrigationSystemIds.LPOH_5_0,
            label: "Low-pressure over-head, application rate well-matched",
          },
          {
            value: irrigationSystemIds.LPOH_5_15,
            label: "Low-pressure over-head, application rate not matched",
          },
          {
            value: irrigationSystemIds.Traveller_15_0,
            label: "Traveller, application rate well-matched",
          },
          {
            value: irrigationSystemIds.Traveller_15_25,
            label: "Traveller, application rate not matched",
          },
        ],
        default: () => irrigationSystemIds.FURROW,
      },
      [settingIds.IRRIGATION_AMOUNT]: {
        options: (settings) => {
          if (
            settings[settingIds.IRRIGATION_SYSTEM] ===
            irrigationSystemIds.FURROW
          ) {
            const furrowOptions = {
              1: [{ value: 100, label: 1 }],
              2: [
                { value: 200, label: 1 },
                { value: 100, label: 2 },
                { value: 67, label: 3 },
              ],
              3: [
                { value: 150, label: 2 },
                { value: 100, label: 3 },
                { value: 75, label: 4 },
              ],
              4: [
                { value: 200, label: 2 },
                { value: 133, label: 3 },
                { value: 100, label: 4 },
                { value: 80, label: 5 },
                { value: 67, label: 6 },
              ],
              999: _.map([65, 80, 95, 110, 125, 150, 200], (value) => ({
                value,
                label: value,
              })),
            };
            return furrowOptions[settings[settingIds.IRRIGATION_ALLOCATION]];
          }
          const sprinklerOptions = {
            1: [
              { value: 50, label: 2 },
              { value: 33, label: 3 },
              { value: 20, label: 5 },
            ],
            2: [
              { value: 50, label: 4 },
              { value: 33, label: 6 },
              { value: 20, label: 10 },
            ],
            3: [
              { value: 50, label: 6 },
              { value: 33, label: 9 },
              { value: 20, label: 15 },
            ],
            4: [
              { value: 50, label: 8 },
              { value: 33, label: 12 },
              { value: 20, label: 20 },
            ],
            999: [
              { value: 20, label: 20 },
              { value: 33, label: 33 },
              { value: 50, label: 50 },
            ],
          };
          return sprinklerOptions[settings[settingIds.IRRIGATION_ALLOCATION]];
        },
        default: (settings) => {
          if (
            settings[settingIds.IRRIGATION_SYSTEM] ===
            irrigationSystemIds.FURROW
          ) {
            const furrowDefaults = {
              1: 100,
              2: 200,
              3: 150,
              4: 200,
              999: 110,
            };
            return furrowDefaults[settings[settingIds.IRRIGATION_ALLOCATION]];
          }
          return 33;
        },
      },
      [settingIds.FERTILISER_AMOUNT]: {
        options: () => [
          {
            label: "Plant: 130 kg/ha | Ratoons: 150 kg/ha",
            value: "130_150",
          },
          {
            label: "Plant: 140 kg/ha | Ratoons: 160 kg/ha",
            value: "140_160",
          },
        ],
        default: () => "130_150",
      },
      [settingIds.DRIP_ENERGY_USED]: {
        isVisible: false,
      },
      [settingIds.FERTILISER_DISTRIBUTION]: {
        ...mackayFertiliserSetting,
        isDisabled: () => false,
        isVisible: true,
      },
      [settingIds.SECONDARY_FERTILISER_DISTRIBUTION]: {
        ...mackayFertiliserSetting,
        isDisabled: () => true,
        isVisible: false,
      },
      [settingIds.IS_BURNT]: {
        isDisabled: true,
        isCheckedWhenDisabled: false,
      },
      [settingIds.DRY_OFF_DAYS]: {
        options: () => [
          { label: "6 weeks", value: 42 },
          { label: "8 weeks", value: 56 },
        ],
        default: () => 42,
      },
      [settingIds.RATOON]: {
        options: () => [
          { value: "ALL", label: "Averaged across all crops" },
          { value: 0, label: "For plant only" },
          { value: 1, label: "For R1 only" },
          { value: 2, label: "For R2 only" },
          { value: 3, label: "For R3 only" },
          { value: 4, label: "For R4 only" },
          { value: 5, label: "For R5 only" },
          { value: 6, label: "For R6 only" },
        ],
        default: () => "All",
      },
      [settingIds.HARVEST_YEARS]: {
        min: 1973,
        max: 2022,
      },
    },
    scenarioDefaults: {
      current: {
        ..._.omit(defaultSettings, [
          settingIds.REGION,
          settingIds.WEATHER_STATION,
        ]),
        [settingIds.DRY_OFF_DAYS]: 42,
        [settingIds.FERTILISER_AMOUNT]: "130_150",
        [settingIds.FERTILISER_DISTRIBUTION]: "MW_Surf2",
        [settingIds.IRRIGATION_ALLOCATION]: 3,
        [settingIds.IRRIGATION_AMOUNT]: 100,
        [settingIds.IRRIGATION_FREQUENCY]: swBased50late.value,
        [settingIds.IRRIGATION_SYSTEM]: irrigationSystemIds.FURROW,
        [settingIds.PLANTING]: "MW_01MayR6V1",
        [settingIds.SOIL]: "Pind",
        [settingIds.SURFACE_ENERGY_USED]: 70,
        [settingIds.TILLAGE]: ["MW_T3"],
      },
      new: {
        ..._.omit(defaultSettings, [
          settingIds.REGION,
          settingIds.WEATHER_STATION,
        ]),
        [settingIds.DRY_OFF_DAYS]: 42,
        [settingIds.FERTILISER_AMOUNT]: "130_150",
        [settingIds.FERTILISER_DISTRIBUTION]: "MW_Surf2",
        [settingIds.IRRIGATION_ALLOCATION]: 3,
        [settingIds.IRRIGATION_AMOUNT]: 33,
        [settingIds.IRRIGATION_FREQUENCY]: swBased50late.value,
        [settingIds.IRRIGATION_SYSTEM]: irrigationSystemIds.LPOH_5_15,
        [settingIds.PLANTING]: "MW_01MayR6V1",
        [settingIds.SURFACE_ENERGY_USED]: 120,
        [settingIds.SOIL]: "Pind",
        [settingIds.TILLAGE]: ["MW_T3"],
      },
    },
  },
};

export default _.pickBy(regions, (region) => region.isEnabled);
