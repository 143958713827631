import _ from "lodash";
import Loess from "loess";

export default function generateMovingAverage(data) {
  if (data.length < 5) {
    return data;
  }
  const input = {
    x: _.map(data, ({ x }) => _.toNumber(x)),
    y: _.map(data, ({ y }) => _.toNumber(y)),
  };
  const model = new Loess(input, {
    span: 5,
    degree: 2,
  });
  const { fitted } = model.predict();
  const movingAverageData = _.zip(_.map(data, "x"), fitted).map((item) => ({
    x: item[0],
    y: item[1],
  }));
  return movingAverageData;
}
