import FixedContainer from "./FixedContainer";
import chartConfig, { yAxisLabelMinWidth } from "./chartConfig";
import { COLOR_A, COLOR_B } from "./colors";
import generateMovingAverage from "./movingAverage";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartTimeSeries({
  data,
  unit,
  xLabel,
  decimalPlaces,
  format,
  stats,
  isLoading,
}) {
  const scenarios = _.keys(_.first(_.values(data)));
  const series = [];
  scenarios.forEach((scenario) => {
    series.push({
      name: _.startCase(scenario),
      type: "line",
      data: _.keys(data).map((year) => ({
        y: data[year][scenario],
        x: year,
      })),
    });
  });
  const isShowingMovingAverage = _.values(data).length > 4;
  if (isShowingMovingAverage) {
    scenarios.forEach((scenario) => {
      const scenarioData = _.map(_.keys(data), (x) => ({
        x: _.toNumber(x),
        y: data[x][scenario],
      }));
      series.push({
        name: `Moving average for ${scenario}`,
        type: "line",
        data: generateMovingAverage(scenarioData),
      });
    });
  }
  return (
    <>
      <FixedContainer>
        <ApexChart
          options={chartConfig({
            colors: [COLOR_A, COLOR_B],
            xaxis: {
              type: "datetime",
              title: {
                text: xLabel,
              },
              labels: {
                format: "yyyy",
              },
            },
            tooltip: {
              x: {
                format: "yyyy",
              },
              y: {
                formatter: (value) => format(value).value,
              },
            },
            yaxis: {
              title: {
                text: unit,
              },
              decimalsInFloat: decimalPlaces,
              labels: {
                minWidth: yAxisLabelMinWidth,
              },
            },
            grid: {
              show: false,
            },
            stroke: {
              width: 2,
              dashArray: [0, 0, 3, 3],
            },
            annotations: {
              yaxis: [
                {
                  y: stats.mean.new,
                  borderColor: COLOR_A,
                  strokeDashArray: [8, 4],
                },
                {
                  y: stats.mean.current,
                  borderColor: COLOR_B,
                  strokeDashArray: [8, 4],
                },
              ],
            },
            legend: {
              customLegendItems: scenarios.map(_.startCase),
            },
          })}
          series={isLoading ? [] : series}
          height="100%"
        />
      </FixedContainer>
      <div className="text-center small mt-2">
        <ul className="list-inline mb-0">
          <li className="list-inline-item">
            <strong>Dashed line</strong> Long-term average
          </li>
          {isShowingMovingAverage && (
            <li className="list-inline-item">
              <strong>Dotted line</strong> 5-year moving average
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

ChartTimeSeries.propTypes = {
  xLabel: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  unit: PropTypes.string.isRequired,
  decimalPlaces: PropTypes.number.isRequired,
  format: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    mean: PropTypes.shape({
      new: PropTypes.number.isRequired,
      current: PropTypes.number.isRequired,
    }).isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
};

ChartTimeSeries.defaultProps = {
  stats: {
    mean: {
      new: 0,
      current: 0,
    },
  },
};
