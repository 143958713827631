export const greenhouseGasEmission = {
  title: "Greenhouse Gas Emission",
  contents: (
    <>
      <p>
        Multiple sources of greenhouse gas (GHG) emission were considered: (1)
        CO2 emission during decomposition of soil organic material, (2) nitrous
        oxide (N2O) emission during nitrification and denitrification, (3)
        emission due to pumping, and (4) N2O and CH4 (methane) emission due to
        residue burning.
      </p>
      <p>
        The first two sources were estimated by the APSIM SoilN module. APSIM
        assumes, by default, that 60% of decomposed carbon (C) is lost to the
        atmosphere. N2O emission during nitrification is calculated as a
        proportion of nitrified N (Li et al., 2007). N2O emission during
        denitrification is calculated by combining predictions of
        denitrification with the ratio of N2 to N2O emitted during
        denitrification (Grosso et al., 2000). The amount of GHG emission due to
        pumping depends on the fuel used and energy required to pump a unit of
        water. CO2 emission factors for combustion were extracted from IPCC
        (2006a).
      </p>
      <p>
        According to IPCC (2006b), for estimating GHG emission due to plant
        residue burning in agricultural areas, only CH4 and N2O emissions should
        be considered because the crop growth in a 1-year period would
        compensate for the CO2 emissions. This hypothesis was also applied to CO
        as it is quickly converts to CO2 in the atmosphere. NOx emissions were
        also not considered in our estimations because their global warming
        potential is uncertain.
      </p>
      <p>
        The IPCC (2006b) methodology was applied to estimate GHG emissions from
        burning. The emission factors adopted were 2.7 and 0.07 for CH4 and N2O
        (g kg-1 of burned dry matter), respectively (Andreae and Merlet, 2001).
        At this stage, GHG emission resulting from the combustion of fuel is not
        considered.
      </p>
    </>
  ),
};

export const irrigationStrategy = (region) => ({
  title: "Irrigation strategy",
  contents: (
    <>
      {region === "Burdekin" && (
        <>
          <p>
            Five irrigation frequency (i.e., timing) scenarios were defined,
            including low, medium, and high-frequency regimes (only for furrow)
            along with two soil water deficit (SWD)-based irrigation scenarios.
          </p>
          <p>
            For the low, medium, and high-frequency scenarios, irrigation
            scheduling was done separately for two parts of the growing season.
            Three to six irrigation events were simulated between the beginning
            of crop establishment and a maximum of 91 days later. The irrigation
            frequency increases after establishment as demand for water
            increases before slowing as demand slows before dry-off (i.e., the
            period before harvest when irrigation is stopped). During peak
            demand, irrigation was performed regularly (every 7, 10 and 14
            days). Three scenarios (5, 8 and 11 weeks) were defined for the
            dry-off period.
          </p>
          <p>
            In the SWD-based scenarios, irrigation was triggered when SWD over a
            predetermined depth (90 cm in furrow irrigation and 60 cm in drip
            irrigation) was larger than a specific portion of plant available
            water (PAW; 40 or 60% in furrow irrigation and 30% in drip
            irrigation). SWD thresholds were halved during the establishment
            phase (90, 80, 70, 60, and 60 days for plant and ratoon crops,
            respectively). For both irrigation systems, water was applied as
            much as the amount set by the irrigation amount scenario.
          </p>
        </>
      )}
      {region === "Mackay" && (
        <>
          <p>
            In this region, only SWD-based scenarios in combination with six
            water allocation scenarios (0, 1, 2, 3, 4 ML/ha along with unlimited
            allocation) were simulated. When unlimited water allocation was
            simulated, seven (65, 80, 95, 110, 125, 150, and 200 mm) and two (25
            and 50 mm) irrigation amount scenarios were simulated for furrow and
            sprinkler irrigation systems, respectively. One scenario (4 weeks)
            was defined for the dry-off period.
          </p>
          <p>
            The zero-allocation scenario was considered a separate irrigation
            system called 'Rainfed'. Irrigation was triggered when SWD over a
            predetermined depth (90 cm) was larger than 70% of plant available
            water. One irrigation was applied after each event of N application
            (subject to water availability). APSIM triggered SWD-based
            irrigations based on two strategies: (1) irrigation was applied from
            planting/harvest, and (2) irrigation was applied after crop reached
            growth stage 3.75, i.e., close to the beginning of cane growth. One
            irrigation ('salvation' irrigation) was applied if the plant crop
            did not emerge 35 days after planting and SWD was larger than 40% of
            PAWs. A minimum irrigation cycle of 14 days was applied.
          </p>
        </>
      )}
    </>
  ),
});

export const irrigationSystems = (region) => ({
  title: "Irrigation systems",
  contents: (
    <>
      {region === "Burdekin" && (
        <p>
          Currently two types of irrigation systems are included in i-RAT: (1)
          furrow irrigation, and (2) subsurface drip irrigation.
        </p>
      )}
      {region === "Mackay" && (
        <p>
          Currently, three types of irrigation systems are included in i-RAT:
          (1) furrow irrigation (no transmission loss), (2) low-pressure
          overhead sprinkler irrigation (LPOH; 5% transmission loss with 0, 10
          and 20% runoff), and (3) traveller sprinkler irrigation (15%
          transmission loss with 0, 10 and 25% runoff). Transmission loss is
          intended to account for water loss due to lack of uniformity, wind
          drift, and spray evaporation.
        </p>
      )}
      {region === "Burdekin" && (
        <p className="alert alert-info" role="alert">
          The subsurface drip irrigation can only be selected if the tillage
          scenario is Zonal. On the other hand, non-Zonal tillage scenarios can
          be selected only if subsurface drip irrigation system is not selected
          in any of the irrigation scenarios.
        </p>
      )}
      <h4>Furrow irrigation</h4>
      <p>
        Furrow irrigation is conducted by creating small parallel channels along
        the length of the field, usually parallel to the direction of its
        predominant slope. Water is applied to the top end of each furrow and
        flows down the field under the influence of gravity. Water may be
        supplied using gated pipe, siphon and head ditch, or bankless systems.
        The speed of water movement is determined by many factors such as slope,
        surface roughness, and furrow shape, but most importantly by the inflow
        rate and soil infiltration rate.
      </p>
      <p>
        The spacing between adjacent furrows is governed by the crop species,
        common spacings typically range from 0.75 to 2 m (2.5 to 6.6 ft). The
        crop is planted on the ridge between furrows which may contain a single
        row of plants or several rows in the case of a bed-type system. Furrows
        may range anywhere from less than 100 m (110 yd) to 2,000 m (1.2 mi)
        long depending on the soil type, location, and crop type. Shorter
        furrows are commonly associated with higher uniformity of application
        but result in increasing potential for runoff losses. Furrow irrigation
        is particularly suited to broadacre row crops such as cotton, maize, and
        sugarcane. It is also practiced in various horticultural industries such
        as citrus, stone fruit, and tomatoes.
      </p>
      <p>
        The water can take a considerable period of time to reach the other end,
        meaning water has been infiltrating for a longer period of time at the
        top end of the field. This results in poor uniformity with high
        application at the top end with lower application at the bottom end. In
        most cases the performance of furrow irrigation can be improved through
        increasing the speed at which water moves along the field (the advance
        rate). This can be achieved through increasing flow rates or through the
        practice of surge irrigation. Increasing the advance rate not only
        improves the uniformity but also reduces the total volume of water
        required to complete the irrigation.
      </p>
      {region === "Burdekin" && (
        <>
          <h4>Subsurface drip irrigation</h4>
          <p>
            Subsurface drip irrigation (SDI) involves burying the drip
            irrigation line permanently in a field below the tillage layer. The
            depth and spacing will depend on the type of crop that is intended
            to be grown. Despite considerable initial cost, this type of systems
            can last 20 years plus with proper maintenance.
          </p>
          <p>
            Like regular drip irrigation installed at the surface, SDI systems
            can reduce water use by 50%. They use an emitter line usually
            treated with herbicide to prevent roots from growing into the tubing
            placed parallel to each other at about 15-30 cm below the soil
            surface. The high efficiency of drip irrigation results from two
            primary factors: (1) the water soaks into the soil before it can
            evaporate or run off, and (2) the water is only applied where it is
            needed, rather than being subject to wind.
          </p>
          <p>
            SDI systems requires less maintenance than surface irrigation, and
            there is also less chance of surface soil saturation and runoff.
            However, they also add some challenges in that the drip lines are
            not visible to inspect for plugged emitters and damage. Since the
            soil surface will rarely show wetness, soil moisture monitoring will
            be needed to provide data on the results of water applications.
          </p>
        </>
      )}
      {region === "Mackay" && (
        <>
          <h4>Low-pressure overhead sprinkler irrigation</h4>
          <p>
            A low-pressure overhead irrigation system, commonly known as a
            sprinkler irrigation system, is a method of providing water to crops
            by distributing it through sprinklers located above the ground. This
            system is widely used in cropping systems to deliver water
            efficiently and uniformly to plants.
          </p>
          <p>
            Unlike high-pressure systems that use pressurized water to
            forcefully spray the water over the area, low-pressure systems rely
            on gravity and lower water pressure to distribute the water in a
            gentler manner. In terms of pressure, low-pressure overhead
            irrigation systems typically operate within the range of 15 to 30
            pounds per square inch (psi). This pressure range ensures that the
            water is distributed with adequate force to cover the desired area
            without causing excessive misting or runoff.
          </p>
          <p>
            The size of the nozzle, or sprinkler head, used in these systems can
            vary based on specific requirements. Nozzles come in different types
            and sizes, each designed to deliver water in a particular pattern
            and flow rate. The selection of the nozzle size depends on factors
            such as crop type, spacing, soil type, and desired application rate.
            Common nozzle sizes for low-pressure overhead irrigation systems
            range from 1/8 inch to 1/2 inch in diameter.
          </p>
          <p>
            Low-pressure systems are designed to minimize water wastage. By
            utilizing gravity and lower water pressure, they deliver water more
            efficiently, reducing runoff and evaporation. As low-pressure
            systems operate with lower water pressure, they require less energy
            to pump water. This can result in lower operational costs compared
            to high-pressure systems.
          </p>
          <p>
            Low-pressure sprinkler systems can be used in various agricultural
            applications, such as field crops, orchards, vineyards, and
            nurseries. They can also be adapted for use in residential gardens
            and landscaping. These systems provide a uniform distribution of
            water, ensuring that all plants receive adequate moisture. This
            helps to promote healthy growth and minimize overwatering or
            underwatering. The gentle application of water in a low-pressure
            system helps to prevent soil erosion. By avoiding excessive force
            and surface disruption, the soil structure remains intact, reducing
            the risk of erosion.
          </p>
          <p>
            Low-pressure sprinkler systems offer adjustable spray patterns,
            allowing for customization based on crop or plant requirements.
            Different nozzle types and configurations can be used to achieve the
            desired watering pattern. Compared to complex high-pressure systems,
            low-pressure systems are relatively simple to install and maintain.
            They typically require less sophisticated equipment and can be set
            up using standard plumbing materials.
          </p>
          <p>
            It's important to note that the exact specifications of a
            low-pressure overhead irrigation system, including pressure range,
            nozzle size, and sprinkler spacing, can vary depending on the
            specific requirements of the cropping system, climate conditions,
            and the farmer's preferences. Agricultural engineers, irrigation
            specialists, and manufacturers can provide detailed guidance and
            expertise in designing and optimizing these systems for individual
            farming operations.
          </p>
          <h4>Traveller sprinkler irrigation</h4>
          <p>
            A gun irrigation system, also known as traveller, is a type of
            irrigation system commonly used in cropping systems to distribute
            water over large areas. It is designed to cover a significant
            distance and provide water to crops efficiently. The centrepiece of
            the system is the gun sprinkler itself. It is a large sprinkler head
            mounted on a tripod or a riser. The sprinkler gun can rotate
            horizontally and vertically, allowing it to cover a wide area.
          </p>
          <p>
            The system requires a sufficient water source such as a well, pond,
            reservoir, or a connected water supply system. Water is typically
            pumped to the gun sprinkler using a high-pressure pump. A pumping
            station is responsible for pressurizing the water to the required
            level for the gun sprinkler to operate effectively. The pressure
            range for gun irrigation systems is typically higher than that of
            low-pressure overhead systems, often ranging from 40 to 100 pounds
            per square inch (psi) or even higher.
          </p>
          <p>
            The water is transported from the pumping station to the gun
            sprinkler through a series of pipes. These pipes are generally
            larger in diameter compared to low-pressure systems to accommodate
            the higher water flow and pressure. The size of the nozzle used in
            gun irrigation systems can vary depending on the specific
            requirements. Nozzles are designed to provide a specific water flow
            rate and range of throw, determining how far the water is projected.
            Nozzle sizes commonly range from 1 inch to 2 inches or more in
            diameter.
          </p>
          <p>
            Gun sprinklers allow for adjusting the trajectory of water
            projection. This feature is useful to accommodate different field
            topographies and wind conditions, ensuring optimal water
            distribution. The range and coverage area of a gun irrigation system
            can be substantial. The range can extend from several tens to
            hundreds of feet, depending on the specific model and water
            pressure. The coverage area can vary, but a single gun sprinkler can
            typically cover an area of several acres.
          </p>
          <p>
            Gun irrigation systems are well-suited for large-scale farming
            operations and open-field crops. They offer advantages such as high
            application rates, quick water distribution over large areas, and
            the ability to irrigate uneven terrain. However, they require
            careful planning and design to ensure uniform water distribution and
            minimize water losses due to wind drift or evaporation.
          </p>
          <p>
            It's important to consult with irrigation specialists,
            manufacturers, or agricultural engineers to determine the most
            appropriate gun sprinkler model, pressure requirements, nozzle size,
            and spacing based on the specific cropping system, field size, and
            environmental conditions.
          </p>
        </>
      )}
    </>
  ),
});

export const cropFallowCycle = (region) => ({
  title: "Crop/Fallow Cycle",
  contents: (
    <>
      {region === "Burdekin" && (
        <p>
          Six scenarios were defined with planting times ranging from March 15
          to August 15 with a plant crop, four ratoons, and bare fallow.
          Sugarcane planting was simulated for the cultivar 'Q117' considering
          10 plants/m2 and a planting depth of 15 cm. Plant crops were harvested
          12-15 months after planting while ratoons were harvested 7-13 months
          after the harvest of the previous crop, depending on the selected
          scenario.
        </p>
      )}
      {region === "Mackay" && (
        <p>
          Five scenarios were defined with planting times ranging from April 1
          to September 1 with a plant crop, four ratoons, and bare fallow.
          Sugarcane planting was simulated for the cultivar 'Q117' considering
          ten plants/m2 and a planting depth of 15 cm. Plant crops were
          harvested 13-15 months after planting, while ratoons were harvested
          11-13 months after the harvest of the previous crop, depending on the
          selected scenario.
        </p>
      )}
    </>
  ),
});

export const nFertilisation = (region) => ({
  title: "N Fertilisation",
  contents: (
    <>
      {region === "Burdekin" && (
        <>
          <p>
            Three scenarios were simulated for the N application rate: 180, 200
            and 220 kg/ha. The same amount is applied to plant and ratoon crops.
          </p>
          <p>
            For plant sugarcane, 25% of N fertiliser was applied one day after
            planting day, and the rest 90 days after planting. For ratoons, all
            N was applied one day after harvest of the previous plant/ratoon.
            Under SWD-Based irrigation scenarios, one irrigation event was
            triggered one day after N was applied.
          </p>
        </>
      )}
      {region === "Mackay" && (
        <>
          <p>
            Two scenarios were defined for the N application rate: (1) 130
            (plant) and 150 (ratoons) kg/ha, and (2) 140 (plant) and 160
            (ratoons) kg/ha.
          </p>
          <p>
            Two scenarios were defined for N fertiliser splitting for the plant
            crop: (1) all the fertiliser was applied in one dose the day after
            planting, or (2) 50% was applied the day after planting, and the
            rest was used 90 days later. N fertiliser was applied in one dose 30
            days after the previous harvest for the ratoon crops.
          </p>
        </>
      )}
    </>
  ),
});

export const residueBurning = {
  title: "Residue burning",
  contents: (
    <p>
      The user has the option to choose whether crop residue is burnt before a
      harvest. In APSIM-Sugarcane, a fraction of leaf (live and senesced) and
      cabbage can be 'burnt' at harvest, with the remaining material becoming
      surface residue in the APSIM-SurfaceOM module. Here, 85% of the leaf and
      cabbage was assumed to be 'burnt'. Choosing to keep crop residue instead
      of burning it was only possible if zonal tillage scenarios were selected.
    </p>
  ),
};

export const tillage = (region) => ({
  title: "Tillage",
  contents: (
    <>
      <p>
        In APSIM, tillage operations can have two effects: (1) reduction in the
        soil curve number (CN) value (i.e., reducing run-off), and (2)
        incorporation of plant residuals in the soil. Each operation can cause
        one or both effects. Tillage depth, CN reduction, a fraction of residue
        incorporated, and cumulative rain required to reset the value of CN to
        its original value were determined for a wide range of tillage
        operations and were implemented in relevant APSIM input files.
      </p>
      <p>
        Any operation in the model, including planting and tillage, was subject
        to soil workability, i.e., an operation was plausible if available
        moisture in the first soil layer was less than the Drained Upper Limit
        (DUL) of the layer. If this criterion was not met, the operation would
        be postponed.
      </p>
      {region === "Burdekin" && (
        <p className="alert alert-info" role="alert">
          Non-Zonal tillage scenarios can be selected only if subsurface drip
          irrigation system is not selected in any of the irrigation scenarios.
          On the other hand, subsurface drip irrigation can only be selected in
          any of the irrigation scenarios if the tillage scenario (in Paddock
          settings) is Zonal.
        </p>
      )}
      {region === "Burdekin" && (
        <p>
          It was assumed that sugarcane plants were destroyed{" "}
          <strong>three weeks</strong> after harvesting the 4th ratoon (Delay
          for Kill; DFK). If there was a delay in sugarcane planting or harvest,
          DFK was shortened by a maximum of <strong>7 days</strong> to avoid
          significant changes in the timing of operations. The first tillage, if
          any, was performed one day after the DFK period. Two tillage
          operations could be performed on the same day. Operations would be
          conducted if postponed for more than ten days, regardless of soil
          workability.
        </p>
      )}
      {region === "Mackay" && (
        <p>
          It was assumed that sugarcane plants were destroyed{" "}
          <strong>one week</strong> after harvesting the 4th ratoon (Delay for
          Kill; DFK). If there was a delay in sugarcane planting or harvest, DFK
          was shortened by a maximum of <strong>5 days </strong>
          to avoid significant changes in the timing of operations. The first
          tillage, if any, was performed one day after the DFK period. Two
          tillage operations could be performed on the same day. Operations
          would be conducted if postponed for more than ten days, regardless of
          soil workability.{" "}
        </p>
      )}
    </>
  ),
});

export const soilData = {
  title: "Soil data",
  contents: (
    <p>
      Six soil profiles were selected (two from the APSoil database, v3.37, and
      four from Donnollan, 1991; Attard et al., 2009; Hesp et al., 2011) that
      represent the dominant soil types in the Burdekin region. These soil
      profiles represented six different classes of soil in terms of plant
      available water (PAW; Low, Medium, and Very High) and water (Very Low,
      Low, Medium, and Very High).
    </p>
  ),
};

export const weatherData = {
  title: "Weather data",
  contents: (
    <>
      <p>
        Currently we are using data from SILO patch point dataset for two
        weather stations:
      </p>
      <ul>
        <li>Ayr DPI Research Station (19.62 S, 147.38 E).</li>
        <li>Clare (19.79 S, 47.23 E).</li>
      </ul>
    </>
  ),
};
