import ChartChangeLegend from "./ChartChangeLegend";
import FixedContainer from "./FixedContainer";
import chartConfig from "./chartConfig";
import {
  COLOR_BETTER,
  COLOR_WORSE,
  COLOR_GRAY_100,
  COLOR_SAME,
} from "./colors";
import { calculateRelativeChange } from "./utils";
import chroma from "chroma-js";
import _ from "lodash";
import PropTypes from "proptypes";
import ApexChart from "react-apexcharts";

export default function ChartHeatmap({
  data,
  isPositiveBetter,
  isHighResolution,
  changeThreshold,
  isLoading,
  showDividers,
  showTooltips,
}) {
  const options = {
    isDataLabelsEnabled: !isHighResolution,
    chunkSize: isHighResolution ? 10 : 5,
    isYaxisShowing: isHighResolution,
    isXaxisShowing: isHighResolution,
    isXaxisTooltipEnabled: isHighResolution,
  };
  const colors = {
    increase: COLOR_SAME,
    decrease: COLOR_SAME,
  };
  if (_.isBoolean(isPositiveBetter)) {
    colors.increase = isPositiveBetter ? COLOR_BETTER : COLOR_WORSE;
    colors.decrease = isPositiveBetter ? COLOR_WORSE : COLOR_BETTER;
  }
  const values = _.reverse(_.keys(data)).map((year) => ({
    year,
    x: year.substring(3, 4),
    y: calculateRelativeChange(data[year].new, data[year].current),
  }));
  const chunks = _.chunk(values, options.chunkSize);
  const series = chunks.map((chunk) => ({
    name: `${_.first(chunk).year} - ${_.last(chunk).year}`,
    data: _.reverse(chunk),
  }));
  let ranges;
  if (isHighResolution) {
    const max = _.max(
      values.filter(({ y }) => _.isFinite(y)).map(({ y }) => Math.abs(y))
    );
    const scale = chroma
      .scale([colors.decrease, COLOR_GRAY_100, colors.increase])
      .domain([-max, 0, max]);
    const step = max / 255;
    ranges = [
      ..._.range(-max, max + 1, step).map((i) => ({
        from: i - step / 2,
        to: i + step / 2,
        color: scale(i).css(),
      })),
      {
        from: 0,
        to: 0,
        color: COLOR_GRAY_100,
      },
    ];
  } else {
    ranges = [
      {
        from: Number.NEGATIVE_INFINITY,
        to: 0,
        color: colors.decrease,
      },
      {
        from: 0,
        to: Number.POSITIVE_INFINITY,
        color: colors.increase,
      },
      {
        from: -changeThreshold,
        to: changeThreshold,
        color: COLOR_SAME,
      },
    ];
  }
  return (
    <>
      <FixedContainer>
        <div className="h-100" style={{ marginTop: -10 }}>
          <ApexChart
            type="heatmap"
            options={chartConfig({
              dataLabels: {
                enabled: isLoading ? false : options.isDataLabelsEnabled,
                formatter: (value, { seriesIndex, dataPointIndex, w }) => {
                  const { year } =
                    w.config.series[seriesIndex].data[dataPointIndex];
                  return year;
                },
              },
              legend: {
                show: false,
              },
              plotOptions: {
                heatmap: {
                  enableShades: false,
                  useFillColorAsStroke: !showDividers,
                  colorScale: {
                    ranges,
                  },
                },
              },
              xaxis: {
                labels: {
                  show: options.isXaxisShowing,
                },
                axisTicks: {
                  show: options.isXaxisShowing,
                },
                tooltip: {
                  enabled: options.isXaxisTooltipEnabled,
                },
              },
              yaxis: {
                show: options.isYaxisShowing,
              },
              tooltip: {
                enabled: showTooltips,
                x: {
                  show: true,
                  formatter: (value, { seriesIndex, dataPointIndex, w }) => {
                    return w.config.series[seriesIndex].data[dataPointIndex]
                      .year;
                  },
                },
                y: {
                  formatter: (seriesName, data) => {
                    const value = _.round(
                      100 *
                        Math.abs(
                          data.series[data.seriesIndex][data.dataPointIndex]
                        ),
                      2
                    );
                    if (!_.isFinite(value)) {
                      return "";
                    }
                    return `${value}%`;
                  },
                  title: {
                    formatter: (seriesName, data) => {
                      const value =
                        data.series[data.seriesIndex][data.dataPointIndex];
                      if (value === Number.POSITIVE_INFINITY) {
                        return "Increased";
                      }
                      if (value === Number.NEGATIVE_INFINITY) {
                        return "Decreased";
                      }
                      if (value > 0) {
                        return "Increased by";
                      }
                      if (value < 0) {
                        return "Decreased by";
                      }
                      return "Changed by";
                    },
                  },
                },
              },
            })}
            series={isLoading ? [] : series}
            height="100%"
          />
        </div>
      </FixedContainer>
      <ChartChangeLegend
        type={_.isBoolean(isPositiveBetter) ? "default" : "neutral"}
        sameColor={isHighResolution ? COLOR_GRAY_100 : COLOR_SAME}
      />
    </>
  );
}

ChartHeatmap.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isPositiveBetter: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isHighResolution: PropTypes.bool,
  changeThreshold: PropTypes.number.isRequired,
  showDividers: PropTypes.bool,
  showTooltips: PropTypes.bool,
};

ChartHeatmap.defaultProps = {
  isHighResolution: false,
  showDividers: true,
  showTooltips: false,
};
