import { useAlerts } from "./Alerts";
import { b64toBlob } from "./utils";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import PropTypes from "proptypes";
import { useState } from "react";

function generatePdfUrl(params) {
  const urlParams = new URLSearchParams({
    ...params,
    url: `${window.location.origin}/pdf`,
  });
  let url = process.env.REACT_APP_API_URL;
  if (process.env.NODE_ENV === "development" && process.env.REACT_APP_PDF_URL) {
    url = process.env.REACT_APP_PDF_URL;
  }
  url += `pdf?${urlParams.toString()}`;
  return url;
}

const alerts = {
  "pdf-failed": {
    id: "pdf-failed",
    type: "danger",
    message: "Failed to generate PDF.",
  },
};

export default function Download({ pdfParameters }) {
  const { addAlert, removeAlert } = useAlerts();
  const [isDownloading, setIsDownloading] = useState(false);
  return (
    <button
      disabled={isDownloading}
      className="btn btn-link text-decoration-none p-0"
      onClick={async () => {
        setIsDownloading(true);
        removeAlert(alerts["pdf-failed"]);
        const element = document.createElement("a");
        try {
          const response = await axios.get(generatePdfUrl(pdfParameters));
          var file = b64toBlob(response.data, "application/pdf");
          element.href = URL.createObjectURL(file);
          element.download = pdfParameters.name;
          document.body.appendChild(element);
          element.click();
        } catch (error) {
          console.error(error);
          addAlert(alerts["pdf-failed"]);
        }
        setIsDownloading(false);
        element.remove();
      }}
      title="Download paddock report PDF"
    >
      <FontAwesomeIcon
        className="me-2"
        icon={isDownloading ? faSpinner : faDownload}
        spin={isDownloading}
      />
      {isDownloading ? "Generating PDF..." : "Download paddock"}
    </button>
  );
}

Download.propTypes = {
  pdfParameters: PropTypes.shape({}).isRequired,
};
