import "./TableCount.scss";
import { generateCountData } from "./utils";
import PropTypes from "prop-types";
import React from "react";

const TableCount = ({ data, isPositiveBetter, changeThreshold }) => {
  const chartData = generateCountData(data, isPositiveBetter, changeThreshold);
  return (
    <div className="table-responsive mt-3">
      <table className="table table-bordered table-sm mb-0">
        <thead>
          <tr>
            <th scope="col">Relative change</th>
            <th scope="col">Number of seasons</th>
          </tr>
        </thead>
        <tbody>
          {chartData.map((item) => (
            <tr className={`irat-table-count__row--${item.theme}`} key={item.x}>
              <th scope="row">{item.x}</th>
              <td>{item.y}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TableCount.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isPositiveBetter: PropTypes.bool,
  changeThreshold: PropTypes.number.isRequired,
};

export default TableCount;
