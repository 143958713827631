import Modal from "./Modal";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "proptypes";
import { useState } from "react";

export default function ScenarioSettingsButton({ children }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="d-grid">
        <button
          className="btn btn-outline-dark d-flex align-items-center justify-content-between"
          onClick={() => setShow(true)}
        >
          <span>Irrigation settings</span>
          <FontAwesomeIcon icon={faSliders} />
        </button>
      </div>
      <Modal
        scrollable
        centered
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="scenario-settings-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="scenario-settings-modal">
            Irrigation settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-dark"
            onClick={() => setShow(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ScenarioSettingsButton.propTypes = {
  children: PropTypes.node.isRequired,
};
