import ConfirmerModal from "./ConfirmerModal";
import "./Header.scss";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "proptypes";

function Header({ signOut, isSaving, username, onAdminClick }) {
  return (
    <div className="position-relative">
      {isSaving && (
        <div className="top-0 left-0 position-absolute text-light m-1">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      )}
      <header className="irat-header p-5">
        <h1 className="irat-header__title">
          <strong>i-RAT</strong>
        </h1>
        <h2 className="text-capitalize text-white">
          Irrigation rapid assessment tool
          <small className="ms-2 irat-header__version">v2</small>
        </h2>
      </header>
      <div className="small top-0 end-0 position-absolute m-2 text-light d-flex align-items-center">
        {username}
        {!!onAdminClick && (
          <>
            <div className="vr mx-2" />
            <button
              onClick={onAdminClick}
              className="btn btn-sm btn-link text-white p-0 m-0"
            >
              admin
            </button>
          </>
        )}
        <div className="vr mx-2" />
        <ConfirmerModal
          onConfirm={signOut}
          title="Sign out"
          body="Are you sure you want to sign out?"
        >
          <button className="btn btn-sm btn-link text-white p-0 m-0 irat-tour-header__sign-out">
            sign out
          </button>
        </ConfirmerModal>
      </div>
    </div>
  );
}

Header.propTypes = {
  signOut: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  onAdminClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

Header.defaultProps = {
  onAdminClick: false,
};

export default Header;
