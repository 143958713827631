import { COLOR_BETTER, COLOR_WORSE, COLOR_SAME } from "./colors";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "proptypes";

export default function ChartChangeLegend({ sameColor, wording, type }) {
  const items = {
    default: [
      {
        color: COLOR_BETTER,
        short: "Better",
        long: "new scenario is better",
      },
      {
        color: sameColor,
        short: "Small/no change",
        long: "small/no change",
      },
      {
        color: COLOR_WORSE,
        short: "Worse",
        long: "new scenario is worse",
      },
    ],
    neutral: [
      {
        color: sameColor,
        short: "Neutral",
        long: "neutral",
      },
    ],
  };
  return (
    <div className="text-center small">
      <ul
        className={classNames("mb-0", {
          "list-inline": wording === "short",
          "list-unstyled": wording === "long",
        })}
      >
        {items[type].map((item) => (
          <li
            key={item.color}
            className={classNames({ "list-inline-item": wording === "short" })}
          >
            <FontAwesomeIcon
              className="me-2"
              icon={faCircle}
              style={{ color: item.color }}
            />
            {item[wording]}
          </li>
        ))}
      </ul>
    </div>
  );
}

ChartChangeLegend.propTypes = {
  sameColor: PropTypes.string,
  wording: PropTypes.oneOf(["short", "long"]),
  type: PropTypes.string,
};

ChartChangeLegend.defaultProps = {
  sameColor: COLOR_SAME,
  wording: "long",
  type: "default",
};
